import { useState, useCallback, useEffect } from "react";
import {
  Text,
  Container,
  Input,
  styled,
  Button,
  Link,
  Card,
  Grid,
  Image,
} from "@nextui-org/react";

import supabase from "../../../supabase";
import backend from "../../../backend";
import { Box } from "../../design-structure/Box";

import TickIcon from "../../icons/Tick";
import googleSearchImage from "../../../assets/google-search-removebg.png";
import { useSearchParams } from "react-router-dom";
import ZeusLogo from "../../../../src/assets/zeus-logo-blue.png";
import EmailSignUpScreenshot from "../../../../src/assets/email-sign-up.png";

const StyledForm = styled("form", {
  size: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  gap: "$md",
});

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function Signup() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [searchParams] = useSearchParams();

  const [signedUp, isSignedUp] = useState(false);
  const [formError, setFormError] = useState(false);

  const updateFields = useCallback(
    (fields) => {
      setFormData((prev) => ({ ...prev, ...fields }));
    },
    [setFormData]
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!formData.email || !formData.password) {
      setFormError({
        status: true,
        message: "None of the fields below can be empty.",
      });
      return;
    }

    if (formData.password.length < 8) {
      setFormError({
        status: true,
        message: "Password needs to be at least 8-character long.",
      });

      return;
    }

    if (!isValidEmail(formData.email)) {
      setFormError({
        status: true,
        message: "Please enter a valid email address.",
      });

      return;
    }

    const { data, error } = await supabase.auth.signUp({
      email: formData.email,
      password: formData.password,
      options: {
        emailRedirectTo: `${import.meta.env.VITE_FRONTEND_URL}/confirm-email`,
      },
    });

    if (error) {
      setFormError({
        status: true,
        message: "An error occured. Please verify the data you've entered.",
      });
      return;
    }

    // Twitter Lead Event
    twq("event", "tw-of0a6-of9vj", {
      email_address: formData.email, // use this to pass a user’s email address
    });
    setFormError(false);
    isSignedUp(true);
  };

  const signupWithGoogle = async () => {
    event.preventDefault();

    // Twitter Lead Event
    twq("event", "tw-of0a6-of9vj", {});
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: `${import.meta.env.VITE_FRONTEND_URL}/confirm-email`,
      },
    });

    const confirmEmail = () => {
      const offer = searchParams.get("offer");
      backend.queryBackend("POST", "/user/init", {
        offer,
      });
    };

    confirmEmail();
  };

  useEffect(() => {
    const plan = searchParams.get("plan");
    if (plan) {
      localStorage.setItem("plan", plan);
    }

    const offer = searchParams.get("offer");
    if (offer) {
      localStorage.setItem("offer", offer);
    }

    if (window.beam) window.beam("/app/sign-up");
  });

  return (
    <>
      {!signedUp && (
        <Grid xs={12} sm={6} css={{ bg: "$gray200" }}>
          <Container
            css={{
              "@smMax": { display: "none" },
              "@md": { pr: "2rem", pl: "6rem", pt: "3rem" },
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center",
            }}
          >
            <Card css={{ p: "$12", mt: "$6" }} variant="bordered">
              <Image
                width={150}
                src={ZeusLogo}
                showSkeleton
                alt="Sign up for an account"
                css={{ mb: "$12" }}
              />
              <Card.Body css={{ ml: "$6", maxWidth: "36rem" }}>
                <Text>
                  <TickIcon />{" "}
                  <b>
                    Let automation and AI be at your service to build backlinks.
                  </b>
                </Text>
                <Text>
                  Enter 1 keyword & get 100s of relevant dofollow backlink
                  targets in less than 2 mins.
                </Text>
                <Text css={{ mt: "$6" }}>
                  <TickIcon /> <b>Everything you need to 10X your links.</b>
                </Text>
                <Text>
                  Auto-verified backlinks, deliverability statuses for emails,
                  screenshot-based screening interface, all key SEO metrics (DR,
                  UR, organic traffic..).
                </Text>
                <Text css={{ mt: "$6" }}>
                  <TickIcon />{" "}
                  <b>Full verified contact details with 1 click.</b>
                </Text>
                <Text>
                  You get the contact's current position, LinkedIn & Twitter
                  pages, and a verified email. No generic emails.
                </Text>
                <Text css={{ mt: "$6" }}>
                  <TickIcon />{" "}
                  <b>
                    Integrated with the best tools in the industry - combined.
                  </b>
                </Text>
                <Text>
                  Google. Ahrefs. Hunter. Snov.io. ClearOut. useArtemis.
                  VoilaNorbert. The list goes on. All at your service. No extra
                  subscription needed.
                </Text>
              </Card.Body>
            </Card>
          </Container>
        </Grid>
      )}
      <Grid xs={12} sm={6}>
        <Container
          css={{
            "@md": { pr: "2rem", pl: "2rem", pt: "0rem" },
            maxWidth: "45rem",
          }}
        >
          {formError.status && (
            <Card
              isHoverable
              variant="bordered"
              css={{ $$cardColor: "$colors$warning" }}
            >
              <Card.Body>
                <Text color="white">{formError.message}</Text>
              </Card.Body>
            </Card>
          )}
          {signedUp ? (
            <Box
              css={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                h1
                css={{
                  ta: "left",
                  mb: "$12",
                }}
              >
                🎉 Hey there - Welcome to Zeus!
              </Text>
              <Text size="$md">
                We've sent you an email to confirm that you want to start
                building backlinks effortlessly with Zeus ;)
              </Text>
              <Box css={{ ta: "center" }}>
                <Text css={{ ta: "center" }} size="$xl" b>
                  Please click the link in the email to continue.
                </Text>
              </Box>
              <Image css={{ mt: "$10" }} src={EmailSignUpScreenshot} />
              <Text css={{ ta: "center" }} size="$md" weight="light">
                If you don't receive this email, contact david@zeusoutreach.com
                to request a new one.
              </Text>
            </Box>
          ) : (
            <StyledForm
              onSubmit={(event) => onSubmit(event)}
              css={{
                justifyContent: "center",
              }}
            >
              <Text
                h1
                css={{
                  ta: "left",
                }}
              >
                👋 Welcome to Zeus
              </Text>

              <Input
                name="email"
                label="Email"
                aria-label="email"
                placeholder="Enter your email"
                onChange={({ target }) => updateFields({ email: target.value })}
              />
              <Input.Password
                name="password"
                label="Password"
                aria-label="password"
                placeholder="*******"
                onChange={({ target }) => {
                  updateFields({ password: target.value });
                }}
              />
              <Button color="secondary" type="submit">
                Sign up
              </Button>
              <Button color="white" onClick={signupWithGoogle}>
                <Image
                  src={googleSearchImage}
                  width="16px"
                  height="16px"
                  className="googleSignUp"
                />{" "}
                Sign up with Google
              </Button>
              <Text span blockquote css={{ m: 0 }}>
                Already have an account? Click here to{" "}
                <Link href="/login">sign in</Link>.
              </Text>
            </StyledForm>
          )}
        </Container>
      </Grid>
    </>
  );
}

export default Signup;
