import {
  Loading,
  Text,
  Table,
  Container,
  Row,
  Button,
  Grid,
  Spacer,
  Badge,
  Image,
  Link,
  Card,
  Modal,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Box } from "../design-structure/Box";
import noCampaign from "../../assets/no-campaign.png";
import screenshotFirstCampaign from "../../assets/screenshots/1st-campaign.png";
import screenshotSecondCampaign from "../../assets/screenshots/2nd-campaign.png";
import screenshotFourthCampaign from "../../assets/screenshots/4th-campaign.png";
import PlusIcon from "../icons/Plus";

import supabase from "../../supabase";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CardWithBackground } from "../design-structure/CardWithBackground";

function Dashboard() {
  const [visible, setVisible] = useState(false);
  const handler = (id) => setVisible({ id });
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };
  const tellaVideos = [
    {
      title: "Create your first campaign",
      url: "https://www.tella.tv/video/cljvwv2jm018k0fmmfwns79qw/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Picking the best backlinks",
      url: "https://www.tella.tv/video/cljvxb6v6011w0fl971jj6yik/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Find emails & export data",
      url: "https://www.tella.tv/video/cljvxt17k00d20flibruz4goz/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [errorCampaign, setErrorCampaign] = useState(false);

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  // Fixes bug with scrolling after seeing modal
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("no_credit")) {
      setErrorCampaign({
        error: true,
        message:
          "You do not have enough email credits to retrieve emails. Please head to Billing page to upgrade your plan. Any questions, contact david@zeusoutreach.com.",
      });
    }
  }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const { data, error } = await supabase
        .from("requests")
        .select("id, keyword, status, type")
        .order("id", { ascending: false });
      if (error) {
        console.log(error);
      }
      setCampaigns(data);

      setIsLoading(false);
      if (window.beam) window.beam("/app/dashboard");

      const channel = supabase
        .channel("table-db-changes")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "requests",
          },
          (payload) => {
            console.log(payload);
            setCampaigns((prevCampaigns) => {
              return prevCampaigns.map((campaign) => {
                if (campaign.id === payload.new.id) {
                  campaign = payload.new;
                }

                return campaign;
              });
            });
          }
        )

        .subscribe();
    };

    fetchCampaigns();
  }, []);

  return (
    <Box>
      <Modal
        width={"70%"}
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {tellaVideos[visible.id] && tellaVideos[visible.id].title}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row justify="center">
            <iframe
              style={{ border: 0 }}
              width={800}
              height={450}
              src={tellaVideos[visible.id] && tellaVideos[visible.id].url}
              allowFullScreen=""
              allowTransparency=""
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onPress={closeHandler}>
            Close modal
          </Button>
        </Modal.Footer>
      </Modal>
      {campaigns && isLoading === false && campaigns.length < 4 && (
        <Grid.Container gap={2}>
          <Grid xs={6} sm={4}>
            <CardWithBackground
              h1="Create your 1st campaign"
              bgImage={screenshotFirstCampaign}
              onPress={() => handler(0)}
            />
          </Grid>
          <Grid xs={6} sm={4}>
            <CardWithBackground
              h1="Pick best backlinks"
              bgImage={screenshotSecondCampaign}
              onPress={() => handler(1)}
            />
          </Grid>

          <Grid xs={6} sm={4}>
            <CardWithBackground
              h1="Find emails & export data"
              bgImage={screenshotFourthCampaign}
              onPress={() => handler(2)}
            />
          </Grid>
        </Grid.Container>
      )}
      <Text h1>Dashboard</Text>
      <Container>
        {errorCampaign && errorCampaign.error && (
          <Card
            isHoverable
            variant="bordered"
            css={{ $$cardColor: "$colors$error", mb: "$5" }}
          >
            <Card.Body>
              <Text color="white">{errorCampaign.message}</Text>
            </Card.Body>
          </Card>
        )}

        <Row justify="right" align="right">
          <Tooltip
            content={"Start here, by creating a campaign"}
            rounded
            placement="left"
            color="warning"
            contentColor="default"
            shadow={true}
            visible
            enterDelay={2000}
            isDisabled={campaigns.length > 3 && isLoading === false}
          >
            <Button
              as={Link}
              onPress={() => {
                if (campaigns.length === 0 && isLoading === false) {
                  navigate("/campaign?onboarding=true");
                } else {
                  navigate("/campaign");
                }
              }}
              animated
              auto
              shadow
              icon={<PlusIcon fill="currentColor" />}
            >
              Create new campaign
            </Button>
          </Tooltip>
        </Row>
      </Container>

      {campaigns.length === 0 && isLoading === false && (
        <Box>
          <Table
            aria-label="Example static collection table"
            css={{
              height: "auto",
              minWidth: "100%",
            }}
            selectionMode="single"
          >
            <Table.Header>
              <Table.Column>CAMPAIGNS</Table.Column>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Text align="center" justify="center">
                    Oh no! You don't have any campaign. Create one to get
                    started.
                  </Text>
                  <Image
                    src={noCampaign}
                    showSkeleton
                    width={500}
                    alt="No campaign created"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Box>
      )}

      {!isLoading && campaigns.length > 0 && (
        <Box>
          <Table
            aria-label="Example static collection table"
            css={{
              height: "auto",
              minWidth: "100%",
            }}
            selectionMode="single"
          >
            <Table.Header>
              <Table.Column>KEYWORD</Table.Column>
              <Table.Column>TYPE</Table.Column>
              <Table.Column>STATUS</Table.Column>
            </Table.Header>
            <Table.Body>
              {campaigns.length > 0 &&
                campaigns.map((campaign) => {
                  const campaignLink = {
                    "Waiting to Pick Search Results": `/campaign/search-results/${campaign.id}`,
                    "Waiting to Pick Backlinks": `/campaign/backlinks/${campaign.id}`,
                    "Waiting to Download Emails": `/campaign/contacts/${campaign.id}`,
                  };
                  return (
                    <Table.Row key={campaign.id}>
                      <Table.Cell>
                        <Link
                          color="text"
                          onPress={() =>
                            navigate(campaignLink[campaign.status])
                          }
                        >
                          {campaign.keyword}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          color="text"
                          onPress={() =>
                            navigate(campaignLink[campaign.status])
                          }
                        >
                          {campaign.type === "Magic Campaign"
                            ? "Magic Search"
                            : campaign.type === "Guest Posts"
                            ? "Organic Search"
                            : "Own Backlinks"}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid.Container gap={0.5}>
                          <Grid xs={12} alignItems="center">
                            <Link
                              color="text"
                              onPress={() =>
                                navigate(campaignLink[campaign.status])
                              }
                            >
                              <Badge
                                size="md"
                                color={
                                  campaign.status.indexOf("Waiting for") > -1 ||
                                  campaign.status.indexOf("Analyzing") > -1
                                    ? "warning"
                                    : campaign.status.indexOf(
                                        "No organic result"
                                      ) > -1
                                    ? "error"
                                    : "primary"
                                }
                                variant={
                                  campaign.status.indexOf("Waiting for") > -1 ||
                                  campaign.status.indexOf("Analyzing") > -1
                                    ? "points"
                                    : "dots"
                                }
                                css={{ mr: "$2" }}
                              >
                                {campaign.status.indexOf("Waiting for") > -1 ||
                                campaign.status.indexOf("Analyzing") > -1
                                  ? ""
                                  : campaign.status.indexOf(
                                      "No organic result"
                                    ) > -1
                                  ? "Error"
                                  : "Action Needed"}
                              </Badge>
                              {campaign.status}{" "}
                              {campaign.status.indexOf("Waiting for Emails") >
                                -1 && <>(takes about 20 mins)</>}
                            </Link>
                          </Grid>
                        </Grid.Container>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <Spacer />
        </Box>
      )}
      {isLoading && (
        <Grid justify="center" align="center">
          <Loading color="primary" size="md">
            {" "}
            Loading Campaigns...
          </Loading>
        </Grid>
      )}
    </Box>
  );
}

export default Dashboard;
