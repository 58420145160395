import {
  Container,
  Grid,
  Text,
  Link,
  Image,
  Button,
  Loading,
} from "@nextui-org/react";
import { useEffect, useState } from "react";

import backend from "../../../backend";
import supabase from "../../../supabase";
import homeImage from "../../../assets/confirm-email.png";
import { loadStripe } from "@stripe/stripe-js";

function ConfirmEmail() {
  const [plan, setPlan] = useState(localStorage.getItem("plan") || false);
  const [offer, setOffer] = useState(localStorage.getItem("offer") || false);
  const [checkoutButtonLoading, isCheckoutButtonLoading] = useState(false);

  useEffect(() => {
    // Insert Twitter Event ID
    twq("event", "tw-of0a6-of9vk", {});

    if (window.beam) window.beam("/app/confirm-email");

    supabase.auth.onAuthStateChange(() => {
      const confirmEmail = () => {
        backend.queryBackend("POST", "/user/init", {
          offer,
        });
      };

      confirmEmail();
    });
  }, []);

  const onClickToCheckout = () => {
    if (plan) {
      isCheckoutButtonLoading(true);
      backend
        .queryBackend("POST", `/user/upgrade/${plan}`)
        .then((data) => data.json())
        .then((data) => {
          async function main() {
            const stripe = await loadStripe(
              import.meta.env.VITE_STRIPE_PUBLIC_KEY
            );
            await stripe.redirectToCheckout({
              sessionId: data.id,
            });
          }

          main();
        });
    }
  };

  return (
    <Container>
      <Image
        src={homeImage}
        showSkeleton
        width={400}
        alt="Sign up for an account"
      />
      <Text
        h1
        css={{
          ta: "center",
        }}
      >
        Email Confirmed! One last step...
      </Text>
      <Grid.Container
        alignItems="center"
        alignContent="center"
        justify="center"
      >
        <Grid
          xs={12}
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          {plan ? (
            <>
              <Button
                color="secondary"
                type="submit"
                onClick={() => onClickToCheckout()}
              >
                {checkoutButtonLoading ? (
                  <Loading color="currentColor" size="sm" />
                ) : (
                  <Text color="white">Click here to continue to checkout</Text>
                )}
              </Button>
            </>
          ) : (
            <Text size="$lg">
              Thank you for confirming your email. Please{" "}
              <Link href="/campaign?onboarding=true">
                click here to get access.
              </Link>
            </Text>
          )}
        </Grid>
      </Grid.Container>
    </Container>
  );
}

export default ConfirmEmail;
