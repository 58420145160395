import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { NextUIProvider } from "@nextui-org/react";

//import "./index.css";
import posthog from "posthog-js";
posthog.init("phc_kYp9XOnm2eNQTztQJAof8K9YAP3ixuWp8uM0cosOJOs", {
  api_host: "https://app.posthog.com",
});

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const config = {
  apiKey: "hbp_DA8CsBLPbU4vevVg7YtUqIIPuPg17n23qaZN",
  environment:
    import.meta.env.VITE_FRONTEND_URL.indexOf("localhost") > -1
      ? "development"
      : "production",
  revision: "git SHA/project version",
  developmentEnvironments: ["development"],
};

const honeybadger = Honeybadger.configure(config);
Honeybadger.afterNotify((err, notice) => {
  Honeybadger.showUserFeedbackForm();
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <NextUIProvider>
        <App />
      </NextUIProvider>
    </HoneybadgerErrorBoundary>
  </React.StrictMode>
);
