import { Container } from "@nextui-org/react";
import { Outlet } from "react-router-dom";
import TopNavBar from "../TopNavBar";

function Index() {
  return (
    <>
      <TopNavBar />
      <Container lg css={{ px: "$12", mt: "$8", "@xsMax": { px: "$10" } }}>
        <Outlet />
      </Container>
    </>
  );
}

export default Index;
