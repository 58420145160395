const backend = {
  getToken: () => {
    return localStorage.getItem("sb-mxgytwoqgdhiqhxahygc-auth-token")
      ? JSON.parse(localStorage.getItem("sb-mxgytwoqgdhiqhxahygc-auth-token"))
          .access_token
      : "";
  },
  queryBackend: async (method = "GET", path, body = {}) => {
    return await fetch(`${import.meta.env.VITE_BACKEND_URL}/api${path}`, {
      method,
      headers: {
        Authorization: "Bearer " + backend.getToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  },
};

export default backend;
