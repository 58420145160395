import { Container, Text } from "@nextui-org/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

function NotFound() {
  const error = useRouteError();

  useEffect(() => {
    if (window.beam) window.beam("/app/not-found");
  });

  return (
    <Container lg css={{ px: "$12", mt: "$8", "@xsMax": { px: "$10" } }}>
      <Text h1>Sorry, the page cannot be found (404).</Text>
      <Text size="$lg">{error.statusText || error.message}</Text>
    </Container>
  );
}

export default NotFound;
