import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Layout } from "./components/design-structure/Layout";

import Dashboard from "./components/pages/Dashboard";
import Billing from "./components/pages/Billing";
import NotFound from "./components/pages/NotFound";
import Index from "./components/design-structure/Index";
import Login from "./components/pages/auth/Login";
import Signup from "./components/pages/auth/Signup";
import ConfirmEmail from "./components/pages/auth/ConfirmEmail";
import ProtectedRoute from "./components/ProtectedRoute";
import CreateCampaign from "./components/pages/tool/CreateCampaign";

import { AuthProvider } from "./authProvider";

import "./App.css";
import PickSearchResults from "./components/pages/tool/PickSearchResults";
import PickBacklinks from "./components/pages/tool/PickBacklinks";
import ScreenLeads from "./components/pages/tool/ScreenLeads";
import PickContacts from "./components/pages/tool/PickContacts";
import Latest from "./components/pages/Latest";
import IndexEmpty from "./components/design-structure/Index-empty";
import Help from "./components/pages/Help";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/latest",
        element: <Latest />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/campaign/search-results/:campaignId",
        element: (
          <ProtectedRoute>
            <PickSearchResults />
          </ProtectedRoute>
        ),
      },
      {
        path: "/campaign/contacts/:campaignId",
        element: (
          <ProtectedRoute>
            <PickContacts />
          </ProtectedRoute>
        ),
      },
      {
        path: "/campaign/backlinks/:campaignId",
        element: (
          <ProtectedRoute>
            <PickBacklinks />
          </ProtectedRoute>
        ),
      },
      {
        path: "/campaign/screen-leads/:campaignId",
        element: (
          <ProtectedRoute>
            <ScreenLeads />
          </ProtectedRoute>
        ),
      },
      {
        path: "/campaign",
        element: (
          <ProtectedRoute>
            <CreateCampaign />
          </ProtectedRoute>
        ),
      },
      {
        path: "/billing",
        element: (
          <ProtectedRoute>
            <Billing />
          </ProtectedRoute>
        ),
      },
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <IndexEmpty />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/confirm-email",
        element: <ConfirmEmail />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </AuthProvider>
  );
}

export default App;
