import {
  Loading,
  Text,
  Table,
  Card,
  Grid,
  Row,
  Badge,
  User,
  Button,
  Link,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "../../design-structure/Box";
import { useParams } from "react-router-dom";
import SearchIcon from "../../icons/Search";

import backend from "../../../backend";
import supabase from "../../../supabase";

function PickSearchResults() {
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const campaignId = useParams();
  const navigate = useNavigate();
  const [campaignType, setCampaignType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [searchResultsSelected, setSearchResultsSelected] = useState(false);

  const onSubmit = () => {
    backend
      .queryBackend("POST", "/campaign/get-backlinks", {
        campaignId: parseInt(campaignId.campaignId),
      })
      .then((data) => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage({
            link: "/billing",
            color: "red",
            message: data.message,
          });
        } else {
          navigate("/");
        }
      });
  };

  useEffect(() => {
    const searchResultsSelectedCount = searchResults.filter(
      (b) => b.Keep === true
    );
    if (searchResultsSelectedCount.length === 0) {
      setSearchResultsSelected(false);
    } else {
      setSearchResultsSelected(true);
    }
  }, [searchResults]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const { data: campaignType } = await supabase
        .from("requests")
        .select("type")
        .eq("id", campaignId.campaignId);
      setCampaignType(campaignType[0].type);

      const { data, error } = await supabase
        .from("searchResults")
        .select(
          'id, URL, Title, Description, "Ahrefs DR", "Ahrefs UR", "Referring Domains", "Dofollow Backlinks", "Total Backlinks", Keep, "Search Traffic", "Traffic Value", user_id'
        )
        .eq("requests_id", campaignId.campaignId)
        .order("id", { ascending: true });
      if (error) {
        console.log(error);
      }

      setSearchResults(data);
      setIsLoading(false);

      if (window.beam) window.beam("/app/search-results");
    };

    fetchSearchResults();
  }, []);

  const updateKeepStatus = async (event) => {
    if (event === "all") {
      setSearchResults((prevSearchResults) => {
        return prevSearchResults.map((searchResult) => {
          searchResult.Keep = true;

          return searchResult;
        });
      });
      const { data, error } = await supabase
        .from("searchResults")
        .upsert(searchResults);
      if (error) {
        console.log(error);
      }
    } else if (
      event === "none" ||
      (!event.anchorKey && !event.currentKey && event.size === 0)
    ) {
      setSearchResults((prevSearchResults) => {
        return prevSearchResults.map((searchResult) => {
          searchResult.Keep = false;

          return searchResult;
        });
      });

      const { data, error } = await supabase
        .from("searchResults")
        .upsert(searchResults);
      if (error) {
        console.log(error);
      }
    } else {
      setSearchResults((prevSearchResults) => {
        return prevSearchResults.map((searchResult) => {
          const newKeep = [...event.values()].includes(
            searchResult.id.toString()
          );

          if (newKeep !== searchResult.Keep) {
            const main = async () => {
              const { error } = await supabase
                .from("searchResults")
                .update({ Keep: !searchResult.Keep })
                .eq("id", searchResult.id);

              if (error) {
                console.log(error);
              }
            };

            main();

            searchResult.Keep = !searchResult.Keep;
          }

          return searchResult;
        });
      });
    }
  };

  return (
    <Box>
      <Grid.Container gap={1}>
        <Grid xs={6} alignItems="center" alignContent="center">
          <Text h1>Search Results</Text>
        </Grid>
        <Grid
          xs={6}
          alignItems="center"
          alignContent="right"
          justify="right"
          align="right"
        >
          <Row justify="right" align="right">
            <Tooltip
              color="primary"
              placement="bottom"
              isDisabled={searchResultsSelected}
              content={
                "Click on search results first to be able to get backlinks"
              }
            >
              <Button
                as={Link}
                onClick={onSubmit}
                animated
                auto
                shadow
                justify="right"
                icon={<SearchIcon fill="currentColor" />}
                disabled={!searchResultsSelected}
              >
                Get backlinks
              </Button>
            </Tooltip>
          </Row>
        </Grid>
      </Grid.Container>

      {errorMessage && (
        <Grid.Container>
          <Grid xs={12}>
            <Card
              variant="bordered"
              css={{
                marginBottom: "$xs",
                $$cardColor: `$colors$red`,
              }}
            >
              <Card.Body>
                {errorMessage.link ? (
                  <Link
                    onPress={() => navigate(errorMessage.link)}
                    className="white-link"
                  >
                    <Text>{errorMessage.message}</Text>
                  </Link>
                ) : (
                  <Text>{errorMessage.message}</Text>
                )}
              </Card.Body>
            </Card>
          </Grid>
        </Grid.Container>
      )}

      {!isLoading && campaignType === "Guest Posts" ? (
        <Box>
          <Table
            aria-label="Example static collection table"
            css={{
              height: "auto",
              minWidth: "100%",
            }}
            selectionMode="multiple"
            defaultSelectedKeys={searchResults
              .filter((searchResult) => searchResult.Keep === true)
              .map((searchResult) => searchResult.id.toString())}
            bordered
            onSelectionChange={updateKeepStatus}
          >
            <Table.Header>
              <Table.Column>SEARCH RESULT</Table.Column>
              <Table.Column>UR</Table.Column>
              <Table.Column>DR</Table.Column>
              <Table.Column>REF. DOMAINS</Table.Column>
              <Table.Column>DOFOLLOW BACKLINKS</Table.Column>
              <Table.Column>SEARCH TRAFFIC</Table.Column>
            </Table.Header>
            <Table.Body>
              {searchResults &&
                searchResults.map((searchResult) => {
                  return (
                    <Table.Row key={searchResult.id}>
                      <Table.Cell>
                        <User
                          squared
                          src={
                            "https://alert-pink-iguana.faviconkit.com/" +
                            new URL(searchResult.URL).hostname +
                            "/256"
                          }
                          name={searchResult.Title}
                          css={{ p: 0, maxW: 1100 }}
                          zoomed
                          pointer
                        >
                          <User.Link href={searchResult.URL} pointer>
                            {searchResult.URL.length > 80
                              ? searchResult.URL.substring(0, 80) + "..."
                              : searchResult.URL}
                          </User.Link>
                        </User>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="flat">
                          {searchResult["Ahrefs UR"]}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="flat">
                          {searchResult["Ahrefs DR"]}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="flat">
                          {searchResult["Referring Domains"]}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="flat">
                          {searchResult["Dofollow Backlinks"]}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell>
                        <Badge variant="flat">
                          {searchResult["Search Traffic"] === -1 ? (
                            <Tooltip content="We were not able to find traffic estimates for this page.">
                              N/A
                            </Tooltip>
                          ) : (
                            searchResult["Search Traffic"]
                          )}
                        </Badge>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>

            <Table.Pagination
              // total number of pages
              total={
                searchResults.length > 10
                  ? Math.ceil(searchResults.length / 10)
                  : 1
              }
              initialPage={1}
              shadow
              noMargin
              align="center"
              rowsPerPage={10}
            />
          </Table>
        </Box>
      ) : (
        <Grid justify="center" align="center">
          <Loading color="primary" size="md">
            {" "}
            Loading Search Results...
          </Loading>
        </Grid>
      )}
    </Box>
  );
}

export default PickSearchResults;
