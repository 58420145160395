import { useAuth } from "../authProvider";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();

  if (auth.user === undefined) return null;

  return auth.user ? children : <Navigate to={"/login"} />;
};

export default ProtectedRoute;
