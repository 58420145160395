import {
  Text,
  Button,
  styled,
  Input,
  Container,
  Collapse,
  Grid,
  Tooltip,
  Card,
  Loading,
  Avatar,
  Link,
  Textarea,
  Checkbox,
} from "@nextui-org/react";
import { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../authProvider";
import googleSearchImage from "../../../assets/google-search.png";
import ahrefsImage from "../../../assets/ahrefs.jpeg";

import backend from "../../../backend";

const StyledForm = styled("form", {
  size: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  gap: "$md",
});

function CreateCampaign() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [onboarding, isOnboarding] = useState(
    searchParams.get("onboarding") === "true" || false
  );

  const [formData, setFormData] = useState({
    keyword: "",
    minDR: 0,
    maxDR: 100,
    magicCampaign: false,
    minTraffic: 0,
    minWords: 0,
  });
  const [isFormError, setIsFormError] = useState({
    status: false,
    message: "",
  });
  const [isBOYBError, setisBOYBError] = useState({
    status: false,
    message: "",
  });
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isBOYBLoading, setIsBOYBLoading] = useState(false);
  const [isBYOB, setIsBYOB] = useState({ enabled: false, backlinks: [] });

  const updateBacklinksField = useCallback(
    (fields) => {
      setIsBYOB((prev) => ({ ...prev, ...fields }));
    },
    [setIsBYOB]
  );

  const BYOBSubmit = () => {
    const backlinks = isBYOB.backlinks;
    if (backlinks.length === 0) {
      setisBOYBError({
        status: true,
        message: "Please enter backlinks so Zeus can get to work.",
      });
      setIsBOYBLoading(false);
      return;
    }

    const isValidUrl = (url) => {
      try {
        return Boolean(new URL(url));
      } catch (e) {
        return false;
      }
    };

    const URLs = backlinks.split("\n").filter(Boolean);
    const invalidURL = URLs.filter((backlink) => {
      return !isValidUrl(backlink);
    });

    if (invalidURL.length > 0) {
      setisBOYBError({
        status: true,
        message: "Please enter valid URLs so Zeus can get to work.",
      });

      setIsBOYBLoading(false);
      return;
    }

    setIsBOYBLoading(true);
    backend
      .queryBackend("POST", "/campaign/new", {
        campaignType: "BYOB",
        keyword: "[Bring Your Own Backlinks]",
        backlinks: URLs,
      })
      .then((data) => data.json())
      .then((data) => {
        if (data.hasOwnProperty("type") && data.type === "no credit") {
          updateErrorStatus({
            status: true,
            message: "You don't have enough credits to create a campaign.",
          });
          setIsBOYBLoading(false);
          return;
        } else if (data.hasOwnProperty("type") && data.type === "bad-URLs") {
          updateErrorStatus({
            status: true,
            message: "Please enter only valid URLs.",
          });
        } else if (data.hasOwnProperty("type") && data.type === "no user") {
          updateErrorStatus({
            status: true,
            message: "No user, please contact support.",
          });
        } else {
          navigate("/");
        }
      });
  };
  const magicCampaignToolTip =
    "This will fetch backlinks directly from Zeus live backlinks database.";
  const googleSearchToolTip =
    "This will fetch backlinks directly from Google Search.";

  const updateFields = useCallback(
    (fields) => {
      setFormData((prev) => ({ ...prev, ...fields }));
    },
    [setFormData]
  );

  const updateErrorStatus = useCallback(
    (fields) => {
      setIsFormError((prev) => ({ ...prev, ...fields }));
    },
    [setFormData]
  );

  const onSubmit = async (event) => {
    event.preventDefault();

    if (formData.keyword === "") {
      updateErrorStatus({ status: true, message: "Keyword cannot be empty" });
      return;
    }

    if (formData.magicCampaign === true) {
      if (formData.minWords === 0 || formData.minTraffic === 0) {
        updateErrorStatus({
          status: true,
          message: "Minimum Words & Traffic must be filled out and > 0",
        });
        return;
      }
    }

    setIsFormLoading(true);

    backend
      .queryBackend("POST", "/campaign/new", formData)
      .then((data) => data.json())
      .then((data) => {
        if (data.hasOwnProperty("type") && data.type === "no credit") {
          updateErrorStatus({
            status: true,
            message: "You don't have enough credits to create a campaign.",
          });
          setIsFormLoading(false);
          return;
        } else {
          navigate("/");
        }
      });
  };

  const openBYOB = () => {
    setIsBYOB({ enabled: !isBYOB.enabled, backlinks: isBYOB.backlinks });
  };

  useEffect(() => {
    if (window.beam) window.beam("/app/create-campaign");

    if (onboarding) {
      $crisp.push([
        "do",
        "message:show",
        [
          "animation",
          {
            url: "https://media.giphy.com/media/IThjAlJnD9WNO/giphy.gif",
            type: "image/gif",
          },
        ],
      ]);

      setTimeout(() => {
        $crisp.push([
          "do",
          "message:show",
          [
            "text",
            "👋 Thanks for signing up! I'm David, Zeus' founder. I'm here to help you if you have any questions!",
          ],
        ]);
      }, 5000);
    }
  }, []);

  return (
    <Container xs css={{ my: "$lg" }}>
      {onboarding && (
        <Grid.Container gap={2}>
          <Grid sm={12} md={12}>
            <Card>
              <Card.Header>
                <Text b>
                  🚀 Let's get you backlink leads by creating your first
                  campaign.
                </Text>
              </Card.Header>
              <Card.Divider />
              <Card.Body css={{ py: "$10" }}>
                <Checkbox
                  isRounded
                  defaultSelected
                  isReadOnly
                  color="primary"
                  size="sm"
                >
                  <b>Organic vs Magic Search </b>
                </Checkbox>
                <Text css={{ mb: "$5", ml: "$10" }}>
                  Organic uses Google as source for backlinks or let Zeus take
                  care of it via Magic Search which relies solely on Ahrefs
                  Backlinks database. Magic Search gives more accurate results,
                  in less time.
                </Text>
                <Checkbox
                  isRounded
                  defaultSelected
                  isReadOnly
                  color="primary"
                  size="sm"
                >
                  <b>
                    Use a keyword that is highly competitive (ie. will yield
                    more leads){" "}
                  </b>
                </Checkbox>
                <Text css={{ mb: "$5", ml: "$10" }}>
                  Pick a keyword to get all the backlinks current SERP holders
                  have - the more competitive the keyword is, the more backlinks
                  you'll get.
                </Text>
                <Checkbox
                  isRounded
                  defaultSelected
                  isReadOnly
                  color="primary"
                  size="sm"
                >
                  <b>Filters only apply to backlink leads</b>
                </Checkbox>
                <Text css={{ ml: "$10" }}>
                  Filters will apply to each of the backlink leads Zeus gets you
                  - all filtered leads don't count towards your quota.
                </Text>
              </Card.Body>
            </Card>
          </Grid>
        </Grid.Container>
      )}
      <Text
        h1
        css={{
          ta: "center",
        }}
      >
        New Campaign
      </Text>
      <Text
        h5
        css={{
          ta: "center",
        }}
      >
        Enter a keyword below to start your new campaign.
      </Text>
      <Grid.Container gap={2}>
        <Grid xs={6} alignContent="center" alignItems="center">
          <Card
            isPressable
            isHoverable
            variant={formData.magicCampaign === false ? "shadow" : "flat"}
            onPress={({ target }) => updateFields({ magicCampaign: false })}
          >
            <Card.Body>
              <Grid.Container>
                <Grid xs={4}>
                  <Avatar
                    css={{ ml: "$5" }}
                    size="xl"
                    src={googleSearchImage}
                    color="gradient"
                  />
                </Grid>
                <Grid xs={8} alignContent="center" alignItems="center">
                  <Tooltip content={googleSearchToolTip} placement="left">
                    <Text b={!formData.magicCampaign}>
                      Organic Search <Text size="$xs">Powered by Google</Text>
                    </Text>
                  </Tooltip>
                </Grid>
              </Grid.Container>
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card
            isPressable
            isHoverable
            variant={formData.magicCampaign === true ? "shadow" : "flat"}
            onPress={({ target }) => updateFields({ magicCampaign: true })}
          >
            <Card.Body>
              <Grid.Container>
                <Grid xs={4}>
                  <Avatar
                    css={{ ml: "$5" }}
                    size="xl"
                    src={ahrefsImage}
                    color="gradient"
                    //bordered
                    //squared
                  />
                </Grid>
                <Grid xs={8} alignContent="center" alignItems="center">
                  <Tooltip content={magicCampaignToolTip} placement="right">
                    <Text b={formData.magicCampaign}>
                      Magic Search <Text size="$xs">Powered by Ahrefs</Text>
                    </Text>
                  </Tooltip>
                </Grid>
              </Grid.Container>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
      <StyledForm onSubmit={(event) => onSubmit(event)}>
        {formData.magicCampaign && (
          <Card
            isHoverable
            variant="bordered"
            css={{ $$cardColor: "$colors$warning" }}
          >
            <Card.Body>
              <Text color="white">
                A Magic Search can use up to 100 credits per campaign.
              </Text>
            </Card.Body>
          </Card>
        )}

        {isFormError.status && (
          <Card
            isHoverable
            variant="bordered"
            css={{ $$cardColor: "$colors$error" }}
          >
            <Card.Body>
              <Text color="white">{isFormError.message}</Text>
            </Card.Body>
          </Card>
        )}
        <Input
          name="keyword"
          label="Keyword"
          aria-label="keyword"
          placeholder="Enter a keyword"
          onChange={({ target }) => updateFields({ keyword: target.value })}
        />
        <Input
          labelLeft="Minimum DR"
          aria-label="Minimum DR"
          type="number"
          placeholder="30"
          onChange={({ target }) =>
            updateFields({ minDR: target.valueAsNumber || 0 })
          }
        />
        <Input
          aria-label="Maximum DR"
          labelLeft="Maximum DR"
          type="number"
          placeholder="100"
          onChange={({ target }) =>
            updateFields({ maxDR: target.valueAsNumber || 100 })
          }
        />
        {formData.magicCampaign && (
          <>
            <Input
              labelLeft="Minimum Words"
              aria-label="Minimum Words"
              placeholder="2,000"
              type="number"
              onChange={({ target }) =>
                updateFields({ minWords: target.valueAsNumber || 0 })
              }
            />

            <Input
              labelLeft="Minimum Traffic"
              aria-label="Minimum Traffic"
              placeholder="2,000"
              type="number"
              onChange={({ target }) =>
                updateFields({ minTraffic: target.valueAsNumber || 0 })
              }
            />
          </>
        )}
        <Button
          color="primary"
          animated
          type="submit"
          shadow
          disabled={isFormLoading}
        >
          {isFormLoading ? (
            <Loading type="points" color="currentColor" size="sm" />
          ) : (
            <p>Create Campaign</p>
          )}
        </Button>
        <Grid>
          <Collapse.Group bordered>
            <Collapse title="What will happen next?">
              <Text>
                After entering a keyword, Zeus will go and fetch relevant leads
                for you right away. The type of leads you get depends on the
                campaign type you've picked: Google Search or Magic Search.
              </Text>
            </Collapse>
            <Collapse title="What are Google & Magic search campaigns?">
              <Text>
                While a Google Search campaign will rely on organic search
                results to find backlinks, Magic Search will ask Ahrefs' own
                backlink database. Because Ahrefs is an SEO-focused tool, it
                will usually yield better, more accurate results, and more
                volume of backlinks. Magic Search campaigns can use your credits
                quickly (ie. up to 100 credits per campaign) so use it wisely.
              </Text>
            </Collapse>
            <Collapse title="How many credits will my campaign use?">
              <Text>
                This entirely depends on how many backlinks you will get. To
                prevent a lot of backlinks from being used, make sure to set
                strong search criteria.
              </Text>
            </Collapse>
          </Collapse.Group>
        </Grid>
        <Grid alignContent="center" alignItems="center">
          <Text
            size="$md"
            css={{
              ta: "center",
            }}
            css={{ justifyContent: "center", display: "flex" }}
          >
            <Tooltip content="Use Zeus' email finding feature only. No backlink credits used.">
              <Link color onClick={() => openBYOB()} css={{ width: "100%" }}>
                ...or you prefer to bring your own backlinks?
              </Link>
            </Tooltip>
          </Text>

          {isBYOB.enabled && (
            <>
              {isBOYBError.status && (
                <Card
                  isHoverable
                  variant="bordered"
                  css={{ $$cardColor: "$colors$error" }}
                >
                  <Card.Body>
                    <Text color="white">{isBOYBError.message}</Text>
                  </Card.Body>
                </Card>
              )}
              <Textarea
                css={{ mt: "$4" }}
                placeholder="Enter your backlinks here (1 per line)"
                size="md"
                fullWidth
                height="200"
                minRows={10}
                onChange={({ target }) =>
                  updateBacklinksField({
                    enabled: isBYOB.enabled,
                    backlinks: target.value,
                  })
                }
              ></Textarea>
              <Button
                color="gradient"
                auto
                shadow
                css={{ mt: "$6", width: "100%" }}
                onClick={() => BYOBSubmit()}
                disabled={isBOYBLoading}
              >
                <p> Import your backlinks</p>
              </Button>
            </>
          )}
        </Grid>
      </StyledForm>
    </Container>
  );
}

export default CreateCampaign;
