import logo from "../assets/zeus-logo-blue.png";

import { Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export const ZeusLogo = () => {
  const navigate = useNavigate();
  return (
    <Image
      css={{ cursor: "pointer" }}
      showSkeleton
      autoResize
      width={160}
      src={logo}
      alt="ZeusOutreach Logo"
      objectFit="scale-down"
      onClick={() => navigate("/")}
    />
  );
};
