import {
  Loading,
  Text,
  Table,
  Grid,
  Row,
  Badge,
  User,
  Button,
  Card,
  Link,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Box } from "../../design-structure/Box";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../icons/Filter";
import { CSVLink } from "react-csv";

import supabase from "../../../supabase";

function PickBacklinks() {
  const [isLoading, setIsLoading] = useState(true);
  const [backlinks, setBacklinks] = useState([]);
  const [backlinkCount, setBacklinkCount] = useState(0);
  const [disableScreenButton, setDisableScreenButton] = useState(true);

  const navigate = useNavigate();
  const campaignId = useParams();

  const onSubmit = () => {
    navigate(`/campaign/screen-leads/${campaignId.campaignId}`);
  };

  useEffect(() => {
    const fetchBacklinks = async () => {
      const data = await supabase
        .from("backlinks")
        .select(
          "id, url_from, ahrefs_rank, domain_rating, title, url_to, anchor, keep, screenshot_url, user_id, search_traffic"
        )
        .eq("requests_id", campaignId.campaignId)
        .order("id", { ascending: true });
      if (data.error) {
        console.log(error);
      }
      setBacklinks(data.data);
      setBacklinkCount(data.data.length);

      setIsLoading(false);
      if (window.beam) window.beam("/app/pick-backlinks");
    };

    fetchBacklinks();
  }, []);

  useEffect(() => {
    setDisableScreenButton(
      backlinks.filter((backlink) => backlink.keep === true).length === 0
    );
  }, [backlinks]);

  const updateKeepStatus = async (event) => {
    if (event === "all") {
      setBacklinks((prevBacklinks) => {
        return prevBacklinks.map((backlink) => {
          backlink.keep = true;

          return backlink;
        });
      });

      const { data, error } = await supabase
        .from("backlinks")
        .upsert(backlinks);
      if (error) {
        console.log(error);
      }
    } else if (
      event === "none" ||
      (!event.anchorKey && !event.currentKey && event.size === 0)
    ) {
      setBacklinks((prevBacklinks) => {
        return prevBacklinks.map((backlink) => {
          backlink.keep = false;

          return backlink;
        });
      });

      const { data, error } = await supabase
        .from("backlinks")
        .upsert(backlinks);
      if (error) {
        console.log(error);
      }
    } else {
      setBacklinks((prevBacklinks) => {
        return prevBacklinks.map((backlink) => {
          const newKeep = [...event.values()].includes(backlink.id.toString());

          if (newKeep !== backlink.keep) {
            const main = async () => {
              const { error } = await supabase
                .from("backlinks")
                .update({ keep: !backlink.keep })
                .eq("id", backlink.id);

              if (error) {
                console.log(error);
              }
            };

            main();

            backlink.keep = !backlink.keep;
          }

          return backlink;
        });
      });
    }
    setDisableScreenButton(
      backlinks.filter((backlink) => backlink.keep === true).length === 0
    );
  };

  const csvHeadersBacklinks = [
    {
      label: "Backlink URL",
      key: "url_from",
    },
    {
      label: "Title",
      key: "title",
    },
    {
      label: "URL Rating",
      key: "ahrefs_rank",
    },
    {
      label: "Domain Rating",
      key: "domain_rating",
    },
    {
      label: "Title",
      key: "title",
    },
    {
      label: "Anchor",
      key: "anchor",
    },
  ];

  return (
    <Box>
      <Grid.Container gap={1}>
        <Grid xs={6} alignItems="center" alignContent="center">
          <Text h1>Backlinks ({backlinkCount})</Text>
        </Grid>
        <Grid
          xs={6}
          alignItems="center"
          alignContent="right"
          justify="right"
          align="right"
        >
          <Row justify="right" align="right">
            <Button
              as={Link}
              css={{ mr: "$10" }}
              disabled={false}
              auto
              light
              color="primary"
              justify="right"
            >
              <CSVLink
                headers={csvHeadersBacklinks}
                filename={"Zeus-Campaign-Backlinks.csv"}
                data={backlinks.filter((backlink) => backlink.keep === true)}
              >
                Download backlinks
              </CSVLink>
            </Button>
            <Tooltip
              color="primary"
              placement="bottom"
              isDisabled={!disableScreenButton}
              content={"Select backlinks first to be able to screen them"}
            >
              <Button
                as={Link}
                onClick={onSubmit}
                disabled={disableScreenButton}
                animated
                auto
                shadow
                justify="right"
                icon={<FilterIcon fill="currentColor" />}
              >
                Screen backlinks
              </Button>
            </Tooltip>
          </Row>
        </Grid>
      </Grid.Container>

      {backlinkCount < 4 && (
        <Grid.Container>
          <Grid xs={12}>
            <Card
              variant="bordered"
              css={{ marginBottom: "$xs", $$cardColor: "$colors$warning" }}
            >
              <Card.Body>
                <Text>
                  <Link
                    onPress={() => navigate("/campaign")}
                    className="white-link"
                  >
                    Zeus has fetched less than 10 backlinks, which isn't
                    optimal. For best results, we recommend to create a new
                    campaign with less strict criteria.
                  </Link>
                </Text>
              </Card.Body>
            </Card>
          </Grid>
        </Grid.Container>
      )}

      <Grid.Container>
        <Grid xs={12}>
          <Card
            //isHoverable
            variant="bordered"
            //css={{ $$cardColor: "$colors$error" }}
            css={{ marginBottom: "$xs" }}
          >
            <Card.Body>
              <Text>
                Select backlinks you want to get contact details for. Zeus will
                then find automatically contact details.
              </Text>
              <Text>
                For best results, select as many relevant backlinks as possible.
                You can always revert and change back your choice later.
              </Text>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>

      {!isLoading ? (
        <Box>
          <Table
            aria-label="Example static collection table"
            css={{
              height: "auto",
              minWidth: "100%",
            }}
            selectionMode="multiple"
            defaultSelectedKeys={backlinks
              .filter((backlink) => backlink.keep === true)
              .map((backlink) => backlink.id.toString())}
            bordered
            onSelectionChange={updateKeepStatus}
          >
            <Table.Header>
              <Table.Column>BACKLINK</Table.Column>
              <Table.Column>
                {backlinks[0] &&
                backlinks[0]["ahrefs_rank"] &&
                backlinks[0]["ahrefs_rank"] === -1
                  ? "TRAFFIC"
                  : "UR"}
              </Table.Column>
              <Table.Column>DR</Table.Column>
              {backlinks[0] &&
              backlinks[0]["ahrefs_rank"] &&
              backlinks[0]["ahrefs_rank"] !== -1 ? (
                <Table.Column>TRAFFIC</Table.Column>
              ) : (
                <Table.Column></Table.Column>
              )}
              {backlinks &&
              backlinks[0] &&
              backlinks[0].anchor !== "" &&
              backlinks[1].anchor !== "" ? (
                <Table.Column>ANCHOR</Table.Column>
              ) : (
                <Table.Column></Table.Column>
              )}
            </Table.Header>
            <Table.Body>
              {backlinks &&
                backlinks
                  .sort((a, b) => b.ahrefs_rank - a.ahrefs_rank)
                  .map((backlink) => {
                    return (
                      <Table.Row key={backlink.id}>
                        <Table.Cell>
                          <User
                            squared
                            src={
                              "https://alert-pink-iguana.faviconkit.com/" +
                              new URL(backlink.url_from).hostname +
                              "/256"
                            }
                            name={
                              backlink.title.length > 80
                                ? backlink.title.substring(0, 80) + "..."
                                : backlink.title
                            }
                            css={{ p: 0, maxW: 1100 }}
                            zoomed
                            pointer
                          >
                            <User.Link href={backlink.url_from} pointer>
                              {backlink.url_from.length > 80
                                ? backlink.url_from.substring(0, 80) + "..."
                                : backlink.url_from}
                            </User.Link>
                          </User>
                        </Table.Cell>
                        <Table.Cell>
                          <Badge variant="flat">
                            {backlink["ahrefs_rank"] === -1 ? (
                              backlink["search_traffic"] === -1 ? (
                                <Tooltip content="We were not able to find traffic estimates for this page.">
                                  N/A
                                </Tooltip>
                              ) : backlink["search_traffic"] === 0 ? (
                                "<1"
                              ) : (
                                backlink["search_traffic"]
                              )
                            ) : (
                              backlink["ahrefs_rank"]
                            )}
                          </Badge>
                        </Table.Cell>
                        <Table.Cell>
                          <Badge variant="flat">
                            {backlink["domain_rating"]}
                          </Badge>
                        </Table.Cell>
                        <Table.Cell>
                          {backlink["ahrefs_rank"] !== -1 ? (
                            backlink["search_traffic"] === -1 ? (
                              <Badge variant="flat">
                                <Tooltip content="We were not able to find traffic estimates for this page.">
                                  N/A
                                </Tooltip>
                              </Badge>
                            ) : backlink["search_traffic"] === 0 ? (
                              <Badge variant="flat">{"<1"}</Badge>
                            ) : (
                              <Badge variant="flat">
                                {backlink["search_traffic"]}
                              </Badge>
                            )
                          ) : (
                            ""
                          )}
                        </Table.Cell>
                        {backlinks &&
                          backlinks[0].anchor !== "" &&
                          backlinks[1].anchor !== "" && (
                            <Table.Cell>
                              <Badge variant="flat">
                                {backlink["anchor"].length > 100
                                  ? backlink.anchor.substring(0, 80) + "..."
                                  : backlink.anchor}
                              </Badge>
                            </Table.Cell>
                          )}
                      </Table.Row>
                    );
                  })}
            </Table.Body>

            <Table.Pagination
              // total number of pages
              total={
                backlinks.length > 10 ? Math.ceil(backlinks.length / 10) : 1
              }
              initialPage={1}
              shadow
              noMargin
              align="center"
              rowsPerPage={10}
            />
          </Table>
        </Box>
      ) : (
        <Grid justify="center" align="center">
          <Loading color="primary" size="md">
            {" "}
            Loading Backlinks...
          </Loading>
        </Grid>
      )}
    </Box>
  );
}

export default PickBacklinks;
