import { Grid, Row, Text, Card, Button } from "@nextui-org/react";

function StandardCard(props) {
  return (
    <Grid xs={6} md={4}>
      <Card>
        <Card.Header>
          <Text b>{props.title}</Text>
        </Card.Header>
        <Card.Divider />
        <Card.Body css={{ py: "$10" }}>
          <Text>{props.description}</Text>
        </Card.Body>
        <Card.Divider />
        {/* <Card.Footer>
          <Row justify="flex-end">
            <Button size="sm" light>
              Cancel
            </Button>
            <Button size="sm">Agree</Button>
          </Row>
        </Card.Footer> */}
      </Card>
    </Grid>
  );
}

export default StandardCard;
