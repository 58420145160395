import { Container, Grid, Text } from "@nextui-org/react";
import StandardCard from "../design-structure/Card";
import { useEffect } from "react";

function Latest() {
  useEffect(() => {
    if (window.beam) window.beam("/app/latest");
  });

  return (
    <Container lg css={{ px: "$12", mt: "$8", "@xsMax": { px: "$10" } }}>
      <Text h1>Zeus Updates</Text>
      <Grid.Container gap={2}>
        <StandardCard
          title="[released] Author & Domain Modes"
          description="Zeus is now able to leverage a proprietary algorithm to find the author name of each article, 
          so you can reach the very best person for your prospects."
        />
        <StandardCard
          title="[released] ClearOut.io partner"
          description="Zeus now integrates with ClearOut, a leading email verification platform to ensure all emails we find are deliverable."
        />
        <StandardCard
          title="[released] useArtemnis partner"
          description="Zeus now uses useArtemis, on top of Snov.io, for email collection and verification."
        />
        <StandardCard
          title="[released] Contact Selection Dashboard"
          description="A brand new contact mechanism which brings multiple ways to quickly scan for contacts."
        />
        <StandardCard
          title="[released] new Search Traffic metric"
          description="Search results and backlinks now include a traffic estimate as well as traffic cost."
        />
        <StandardCard
          title="[released] More accurate DR filtering"
          description="In some cases it could occur that backlinks would be provided outside of the DR range set upon campaign creating. This has been fixed."
        />
        <StandardCard
          title="[released] Faster screenshots"
          description="During the lead screening process, some screenshots may have taken longer than expected to appear. It should no longer be the case."
        />
        <StandardCard
          title="[released] Ahrefs partner"
          description="When creating a new campaign, you'll see the option to use Ahrefs Search as source."
        />
        <StandardCard
          title="[released] Snov.io partner"
          description="Zeus now connects to Snov.io as well as useArtemis to retrieve contact details."
        />
        <StandardCard
          title="[released] Validation for all emails"
          description="Zeus will proceed to validate all emails from contacts in a campaign. This way you are guaranteed to always email real, valid email addresses."
        />
        <Text>
          An idea for a feature you'd like to see added? Email
          david@zeusoutreach.com and submit your idea!
        </Text>
      </Grid.Container>
    </Container>
  );
}

export default Latest;
