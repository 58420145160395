import { Card, Col, Row, Button, Text } from "@nextui-org/react";

export const CardWithBackground = (props) => (
  <Card {...props} isPressable>
    {/* <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
      <Col>
        <Text h3 color="white">
          {props.h1}
        </Text>
      </Col>
    </Card.Header> */}
    <Card.Body css={{ p: 0 }}>
      <Card.Image
        src={props.bgImage}
        objectFit="cover"
        width="100%"
        height="100%"
        alt="Relaxing app background"
      />
    </Card.Body>
    <Card.Footer
      isBlurred
      css={{
        position: "absolute",
        //bg: "white",
        bgBlur: "white",
        borderTop: "$borderWeights$light solid $gray800",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Row>
        <Col>
          <Row justify="center" align="center">
            <Text b>{props.h1}</Text>
          </Row>
        </Col>
        {/* <Col>
          <Row justify="flex-end">
            <Button flat auto rounded>
              <Text
                css={{ color: "inherit" }}
                size={12}
                weight="bold"
                transform="uppercase"
              >
                {props.ctaButtonText}
              </Text>
            </Button>
          </Row>
        </Col> */}
      </Row>
    </Card.Footer>
  </Card>
);
