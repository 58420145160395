import {
  Loading,
  Text,
  Table,
  Grid,
  User,
  Button,
  Badge,
  Dropdown,
  Switch,
  Link,
  Card,
  Image,
  Tooltip,
  Row,
  Pagination,
  Progress,
} from "@nextui-org/react";
import { useEffect, useState, useCallback } from "react";
import { Box } from "../../design-structure/Box";
import { useParams, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import StarIcon from "../../icons/Star";
import snovLogo from "../../../assets/snovio-logo.png";
import voilaNorbertLogo from "../../../assets/voilanorbert-logo.png";
import clearoutLogo from "../../../assets/clearout-logo.jpeg";
import useArtemisLogo from "../../../assets/useArtemis-logo.png";
import zeusLogo from "../../../assets/zeus-logo-letter.png";
import linkedInImage from "../../../assets/linkedin.png";
import twitterImage from "../../../assets/twitter.png";
import noCampaign from "../../../assets/no-campaign.png";

import backend from "../../../backend";

import supabase from "../../../supabase";
import SendIcon from "../../icons/Send";

function PickContacts() {
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [dataShown, setDataShown] = useState("contacts-magic");
  const [backlinks, setBacklinks] = useState([]);
  const [currentLead, setCurrentLead] = useState(1);
  const [isError, setIsError] = useState(false);
  const [viewParams, setViewParams] = useState({
    onlyValid: true,
    onlySelected: false,
  });
  const [domainSearchLoading, setdomainSearchLoading] = useState(false);
  const [showNoDomainEmails, setshowNoDomainEmails] = useState([]);
  const navigate = useNavigate();

  const rightArrowPress = useKeyPress("ArrowRight");
  const leftArrowPress = useKeyPress("ArrowLeft");

  // Hook
  function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
  }

  const campaignId = useParams();

  const handleGetEmail = (contactId) => {
    setContacts((prevContacts) => {
      return prevContacts.map((contact) => {
        if (contact.id === contactId) contact.isEmailBeingFetched = true;
        return contact;
      });
    });
    backend
      .queryBackend("POST", `/campaign/get-email/${contactId}`, {})
      .then((data) => data.json())
      .then((data) => {
        if (data.noEmailFound) {
          setContacts((prevContacts) => {
            return prevContacts.map((contact) => {
              if (contact.id === data.id) {
                contact.email = false;
                contact.isEmailBeingFetched = false;
              }

              return contact;
            });
          });
        } else if (data.error === true && data.type === "no credit") {
          setIsError({
            status: true,
            message:
              "You do not have enough email credits to perform this action. Click here to purchase more email credits.",
          });
          setContacts((prevContacts) => {
            return prevContacts.map((contact) => {
              if (contact.id === data.id) {
                contact.email = false;
                contact.isEmailBeingFetched = false;
              }

              return contact;
            });
          });
        } else {
          setContacts((prevContacts) => {
            return prevContacts.map((contact) => {
              if (contact.id === data.id) {
                contact.email = data.email;
                contact.verification = data.verification;
                contact.type = data.type;
                contact.location = data.location;
                contact.isEmailBeingFetched = false;
                contact.source = data.source;
              }

              return contact;
            });
          });
        }
      });
  };

  const csvHeadersContacts = [
    {
      label: "Backlink URL",
      key: "backlink_id.url_from",
    },
    {
      label: "Title",
      key: "backlink_id.title",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Email Type",
      key: "type",
    },
    {
      label: "Email Status",
      key: "verification",
    },
    {
      label: "First Name",
      key: "first_name",
    },
    {
      label: "Last Name",
      key: "last_name",
    },
    {
      label: "Position",
      key: "position",
    },
  ];

  const csvHeadersBacklinks = [
    {
      label: "Backlink URL",
      key: "url_from",
    },
    {
      label: "Title",
      key: "title",
    },
    {
      label: "URL Rating",
      key: "ahrefs_rank",
    },
    {
      label: "Domain Rating",
      key: "domain_rating",
    },
    {
      label: "Title",
      key: "title",
    },
    {
      label: "Anchor",
      key: "anchor",
    },
  ];

  const startDomainSearch = (backlinkId) => {
    setdomainSearchLoading(true);
    backend
      .queryBackend("POST", `/campaign/get-emails`, {
        mode: "domain",
        backlinkId,
        sendDataBack: true,
        campaignId: campaignId.campaignId,
      })
      .then((data) => data.json())
      .then((data) => {
        if (data.length === 0) {
          setshowNoDomainEmails((prevNoDomainEmails) => [
            ...prevNoDomainEmails,
            backlinkId,
          ]);
        } else {
          setContacts((prevContacts) => {
            data.forEach((contact) => {
              const backlink = backlinks.filter(
                (backlink) => backlink.id === contact.backlink_id
              );
              if (backlink && backlink.length > 0) {
                contact.backlink_id = {
                  id: contact.backlink_id,
                  url_from: backlink[0].url_from,
                  title: backlink[0].title,
                };

                prevContacts.push(contact);
              }
            });
            return [...prevContacts];
          });
        }

        setdomainSearchLoading(false);
      });
  };

  const switchLead = (event) => {
    setCurrentLead(event);
  };

  useEffect(() => {
    if (rightArrowPress) {
      setCurrentLead((prevLead) => {
        return prevLead + 1 >
          backlinks.filter((backlink) => backlink.keep).length
          ? 1
          : prevLead + 1;
      });
    }

    if (leftArrowPress) {
      setCurrentLead((prevLead) => {
        return prevLead - 1 === 0 ? 1 : prevLead - 1;
      });
    }
  }, [leftArrowPress, rightArrowPress]);

  useEffect(() => {
    const fetchContacts = async () => {
      const { data, error } = await supabase
        .from("contacts")
        .select(
          "id, first_name, last_name, email, backlink_id (url_from, title, id), type, verification, keep, user_id, source, position, page, twitter, highlighted"
        )
        .eq("request_id", campaignId.campaignId)
        .order("keep", { ascending: false })
        .order("highlighted", { ascending: true });

      if (error) {
        console.log(error);
      }

      setContacts(
        data.map((d) => ({
          ...d,
        }))
      );

      setIsLoading(false);
      if (window.beam) window.beam("/app/pick-contacts");
    };

    fetchContacts();

    const fetchBacklinks = async () => {
      const data = await supabase
        .from("backlinks")
        .select(
          "id, url_from, ahrefs_rank, domain_rating, title, url_to, anchor, keep, screenshot_url, user_id"
        )
        .eq("requests_id", campaignId.campaignId)
        .order("id", { ascending: true });
      if (data.error) {
        console.log(error);
      }
      setBacklinks(data.data);

      setIsLoading(false);
    };

    fetchBacklinks();
  }, []);

  const showData = (data) => {
    setDataShown(data);
  };

  const updateKeepStatus = async (event) => {
    if (event === "all") {
      setContacts((prevContacts) => {
        return prevContacts.map((contact) => {
          contact.keep = true;

          return contact;
        });
      });
      const { data, error } = await supabase
        .from("contacts")
        .upsert(contacts.map(({ backlink_id, ...keepAttrs }) => keepAttrs));
      if (error) {
        console.log(error);
      }
    } else if (
      (event === "none" ||
        (event.anchorKey === undefined && event.anchorKey === undefined)) &&
      Array.from(event).length === 0
    ) {
      setContacts((prevContacts) => {
        return prevContacts.map((contact) => {
          contact.keep = false;
          return contact;
        });
      });

      const { data, error } = await supabase
        .from("contacts")
        .upsert(contacts.map(({ backlink_id, ...keepAttrs }) => keepAttrs));
      if (error) {
        console.log(error);
      }
    } else {
      setContacts((prevContacts) => {
        return prevContacts.map((contact) => {
          const newKeep = [...event.values()].includes(contact.id.toString());

          if (newKeep !== contact.keep) {
            const main = async () => {
              const { error } = await supabase
                .from("contacts")
                .update({ keep: !contact.keep })
                .eq("id", contact.id);

              if (error) {
                console.log(error);
              }
            };

            main();

            contact.keep = !contact.keep;
          }

          return contact;
        });
      });
    }
  };

  const updateFields = useCallback(
    (fields) => {
      setViewParams((prev) => ({ ...prev, ...fields }));
    },
    [setViewParams]
  );

  return (
    <Box>
      <Grid.Container gap={1}>
        <Grid xs={6} alignItems="center" alignContent="center">
          <Text h1>Contacts</Text>
        </Grid>
      </Grid.Container>
      {isError.status && (
        <Card
          isHoverable
          isPressable
          variant="bordered"
          css={{ $$cardColor: "$colors$error", mb: "$3" }}
        >
          <Card.Body>
            <Text onClick={() => navigate("/billing")} color="white">
              {isError.message}
            </Text>
          </Card.Body>
        </Card>
      )}
      <Grid.Container>
        <Grid xs={8} justify="left">
          <Grid.Container>
            <Grid xs={8} justify="left">
              <Button.Group color="default" auto>
                <Button
                  flat={dataShown !== "contacts-magic"}
                  icon={
                    <StarIcon
                      fill={
                        dataShown === "contacts-magic" ? "white" : "#0372f2"
                      }
                    />
                  }
                  onClick={() => showData("contacts-magic")}
                >
                  Screen by site
                </Button>

                <Button
                  onClick={() => showData("contacts")}
                  flat={dataShown !== "contacts"}
                >
                  See contacts found
                </Button>
                <Button
                  onClick={() => showData("backlinks")}
                  flat={dataShown !== "backlinks"}
                >
                  See all backlinks
                </Button>
              </Button.Group>
            </Grid>
            {dataShown === "contacts-magic" && (
              <Grid xs={4} alignContent="left" alignItems="center">
                <Pagination
                  total={
                    backlinks.filter((backlink) => backlink.keep === true)
                      .length
                  }
                  initialPage={currentLead}
                  page={
                    currentLead >= backlinks.length
                      ? backlinks.length
                      : currentLead
                  }
                  shadow
                  size="lg"
                  loop
                  align="center"
                  rowsPerPage={1}
                  onChange={switchLead}
                />
              </Grid>
            )}

            {dataShown === "contacts" && (
              <Grid xs={4} alignContent="left" alignItems="center">
                <Switch
                  bordered
                  initialChecked={viewParams.onlyValid}
                  onChange={() =>
                    updateFields({
                      onlyValid: !viewParams.onlyValid,
                    })
                  }
                />
                <Text css={{ ml: "$5" }}>Only show valid emails</Text>
                <Switch
                  bordered
                  initialChecked={viewParams.onlySelected}
                  onChange={() =>
                    updateFields({
                      onlySelected: !viewParams.onlySelected,
                    })
                  }
                />
                <Text css={{ ml: "$5" }}>Only selected emails</Text>
              </Grid>
            )}
          </Grid.Container>
        </Grid>
        <Grid xs={4} justify="right">
          <Dropdown>
            <Dropdown.Button shadow color="primary">
              Download data
            </Dropdown.Button>
            <Dropdown.Menu color="primary">
              <Dropdown.Item key="contacts">
                <CSVLink
                  headers={csvHeadersContacts}
                  filename={"Zeus-Campaign-Selected-Contacts.csv"}
                  data={contacts.filter(
                    (contact) => contact.email && contact.keep === true
                  )}
                >
                  Download selected contacts
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item key="all-contacts">
                <CSVLink
                  headers={csvHeadersContacts}
                  filename={"Zeus-Campaign-All-Contacts.csv"}
                  data={contacts.filter((contact) => contact.email)}
                >
                  Download all contacts
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item key="backlinks">
                <CSVLink
                  headers={csvHeadersBacklinks}
                  filename={"Zeus-Campaign-Backlinks.csv"}
                  data={backlinks.filter((backlink) => backlink.keep === true)}
                  onClick={() => {
                    console.log("You click the link"); // 👍🏻 Your click handling logic
                  }}
                >
                  Download all backlinks
                </CSVLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Grid>
      </Grid.Container>

      {!isLoading ? (
        <Box css={{ mt: "$8", px: "0" }}>
          {dataShown === "contacts-magic" &&
            backlinks.length > 0 &&
            [
              backlinks.filter((backlink) => backlink.keep)[
                currentLead - 1 // we only the card that's being requested (to avoid too many simultaneous requests)
              ],
            ].map((backlink) => {
              return (
                <Card key={backlink.id}>
                  <Card.Header>
                    <Row
                      css={{
                        justifyItems: "flex-start",

                        ml: "$5",
                      }}
                    >
                      <User
                        squared
                        src={
                          "https://alert-pink-iguana.faviconkit.com/" +
                          new URL(backlink.url_from).hostname +
                          "/256"
                        }
                        name={backlink.title}
                        css={{ p: 0, maxW: 1100 }}
                        zoomed
                        pointer
                      >
                        <User.Link href={backlink.url_from} pointer>
                          {backlink.url_from.length > 80
                            ? backlink.url_from.substring(0, 80) + "..."
                            : backlink.url_from}
                        </User.Link>
                      </User>
                    </Row>
                    <Row
                      justify="right"
                      css={{
                        mr: "$5",
                      }}
                    >
                      <Grid xs={2} direction="column">
                        <Text small align="center">
                          {backlink["ahrefs_rank"] === -1
                            ? "Traffic Value"
                            : "URL Rating"}
                        </Text>
                        <Text align="center" css={{ mt: 0, mb: 0 }}>
                          {backlink["ahrefs_rank"] === -1
                            ? backlink.traffic_value === -1
                              ? "N/A"
                              : backlink.traffic_value
                            : backlink.ahrefs_rank}
                        </Text>
                        <Progress
                          color="gradient"
                          value={
                            backlink["ahrefs_rank"] === -1
                              ? backlink.traffic_value
                              : backlink.ahrefs_rank
                          }
                          size="xs"
                          shadow
                        />
                      </Grid>

                      <Grid xs={2} direction="column">
                        <Text small align="center">
                          Domain Rating
                        </Text>
                        <Text align="center" css={{ mt: 0, mb: 0 }}>
                          {backlink.domain_rating}
                        </Text>
                        <Progress
                          color="gradient"
                          value={backlink.domain_rating}
                          size="xs"
                          shadow
                          css={{ mx: "$5" }}
                        />
                      </Grid>
                    </Row>
                  </Card.Header>
                  <Card.Divider />
                  <Card.Body>
                    <Grid.Container
                      css={{
                        display: "block",
                      }}
                    >
                      {contacts &&
                        contacts.filter(
                          (contact) => contact.backlink_id.id === backlink.id
                        ).length === 0 && (
                          <Box>
                            <Table
                              aria-label="Example static collection table"
                              containerCss={{
                                minWidth: "100%",
                              }}
                              css={{
                                height: "auto",
                                minWidth: "100%",
                              }}
                              selectionMode="single"
                            >
                              <Table.Header>
                                <Table.Column>CONTACTS</Table.Column>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    {showNoDomainEmails.indexOf(backlink.id) >
                                      -1 && (
                                      <Text align="center" justify="center">
                                        Unfortunately, we were not able to find
                                        any emails. We recommend browsing the
                                        backlink URL directly to find an email
                                        to outreach to.
                                      </Text>
                                    )}

                                    {showNoDomainEmails.indexOf(backlink.id) ===
                                      -1 && (
                                      <Text align="center" justify="center">
                                        Looks like we weren't able to find any
                                        contacts. As an alternative, Zeus can
                                        try a full domain search. <br />A full
                                        domain search means that we may end up
                                        finding generic emails such as contact@,
                                        hello@, etc. <br />
                                        One email credit will be used for every
                                        search, provided at least 1 email is
                                        returned.
                                        {domainSearchLoading ? (
                                          <Button
                                            disabled
                                            size="lg"
                                            bordered
                                            css={{ mt: "$5" }}
                                          >
                                            <Loading
                                              color="currentColor"
                                              size="sm"
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            size="lg"
                                            css={{ mt: "$5" }}
                                            onClick={() => {
                                              startDomainSearch(backlink.id);
                                            }}
                                            target="_blank"
                                            className="visit-site-cta"
                                          >
                                            Start full domain search
                                          </Button>
                                        )}
                                      </Text>
                                    )}

                                    <Image
                                      src={noCampaign}
                                      showSkeleton
                                      width={500}
                                      alt="No campaign created"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Box>
                        )}
                      {contacts &&
                        contacts.filter(
                          (contact) => contact.backlink_id.id === backlink.id
                        ).length > 0 && (
                          <Box>
                            <Table
                              lined
                              aria-label="Example static collection table"
                              css={{
                                height: "auto",
                                minWidth: "100%",
                              }}
                              bordered
                              color="primary"
                              selectionMode="multiple"
                              defaultSelectedKeys={contacts
                                .filter((contact) => contact.keep === true)
                                .map((contact) => contact.id.toString())}
                              onSelectionChange={updateKeepStatus}
                            >
                              <Table.Header>
                                <Table.Column>SOCIALS</Table.Column>
                                <Table.Column>NAME</Table.Column>

                                <Table.Column>POSITION</Table.Column>

                                <Table.Column>EMAIL</Table.Column>
                                <Table.Column>STATUS</Table.Column>
                              </Table.Header>
                              <Table.Body>
                                {contacts &&
                                  contacts.length > 0 &&
                                  contacts
                                    .filter(
                                      (contact) =>
                                        contact.backlink_id.id === backlink.id
                                    )
                                    .map((contact, index) => {
                                      return (
                                        <Table.Row key={contact.id}>
                                          <Table.Cell>
                                            {contact.page !== null && (
                                              <Link
                                                href={contact.page}
                                                target="_blank"
                                              >
                                                <Image
                                                  src={linkedInImage}
                                                  width={20}
                                                  alt="LinkedIn Logo"
                                                  objectFit="cover"
                                                  showSkeleton
                                                  zoomed
                                                />{" "}
                                              </Link>
                                            )}

                                            {contact.twitter !== null && (
                                              <Link
                                                href={contact.twitter}
                                                target="_blank"
                                              >
                                                <Image
                                                  src={twitterImage}
                                                  width={20}
                                                  alt="Twitter Logo"
                                                  objectFit="cover"
                                                  showSkeleton
                                                  zoomed
                                                />
                                              </Link>
                                            )}
                                          </Table.Cell>
                                          <Table.Cell>
                                            <User
                                              bordered
                                              className={
                                                contact.highlighted &&
                                                "pickcontact-user-avatar"
                                              }
                                              description={
                                                contact.highlighted && (
                                                  <Badge
                                                    isSquared
                                                    color="success"
                                                  >
                                                    Article Author
                                                  </Badge>
                                                )
                                              }
                                              text={
                                                contact.first_name ||
                                                (contact.email &&
                                                  contact.email
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    contact.email.slice(1)) ||
                                                "Author"
                                              }
                                              name={
                                                (contact.first_name || "") +
                                                " " +
                                                (contact.last_name || "")
                                              }
                                              squared
                                            />
                                          </Table.Cell>
                                          <Table.Cell>
                                            {contact.position}
                                          </Table.Cell>

                                          <Table.Cell>
                                            {contact.email === null &&
                                              contact.isEmailBeingFetched && (
                                                <Button
                                                  disabled
                                                  auto
                                                  bordered
                                                  color="primary"
                                                  css={{ px: "$13" }}
                                                >
                                                  <Loading
                                                    color="currentColor"
                                                    size="sm"
                                                  />
                                                </Button>
                                              )}
                                            {contact.email === null &&
                                              !contact.isEmailBeingFetched && (
                                                <Button
                                                  color="primary"
                                                  flat
                                                  icon={
                                                    <SendIcon fill="currentColor" />
                                                  }
                                                  onPress={() => {
                                                    handleGetEmail(contact.id);
                                                  }}
                                                >
                                                  Get email
                                                </Button>
                                              )}
                                            {contact.email === false && (
                                              <Button light color="error" auto>
                                                No email found.
                                              </Button>
                                            )}
                                            {contact.email && contact.email}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {contact.verification !== "false" &&
                                              contact.verification !== null && (
                                                <Badge
                                                  color={
                                                    contact.verification ===
                                                    "valid"
                                                      ? "success"
                                                      : "warning"
                                                  }
                                                  variant="flat"
                                                >
                                                  {contact.verification}
                                                </Badge>
                                              )}
                                          </Table.Cell>
                                        </Table.Row>
                                      );
                                    })}
                              </Table.Body>
                            </Table>

                            {contacts.filter(
                              (contact) =>
                                contact.backlink_id.id === backlink.id
                            ).length === 1 &&
                              contacts.filter(
                                (contact) =>
                                  contact.backlink_id.id === backlink.id
                              )[0].highlighted && (
                                <Grid.Container
                                  css={{ justifyContent: "center" }}
                                  align="center"
                                >
                                  <Grid align="center" css={{ mt: "$5" }}>
                                    {showNoDomainEmails.indexOf(backlink.id) >
                                      -1 && (
                                      <Text>
                                        We couldn't find any domain email. No
                                        credit was used.
                                      </Text>
                                    )}
                                    {!domainSearchLoading &&
                                      showNoDomainEmails.indexOf(
                                        backlink.id
                                      ) === -1 && (
                                        <Text>Want more emails?</Text>
                                      )}
                                    {domainSearchLoading && (
                                      <Button
                                        disabled
                                        size="lg"
                                        bordered
                                        css={{ mt: "$5" }}
                                      >
                                        <Loading
                                          color="currentColor"
                                          size="sm"
                                        />
                                      </Button>
                                    )}
                                    {!domainSearchLoading &&
                                      showNoDomainEmails.indexOf(
                                        backlink.id
                                      ) === -1 && (
                                        <Tooltip
                                          content="If we find emails, it will cost 1 email credit in total"
                                          placement="bottom"
                                        >
                                          <Button
                                            size="lg"
                                            css={{ mt: "$5" }}
                                            onClick={() =>
                                              startDomainSearch(backlink.id)
                                            }
                                            target="_blank"
                                            className="visit-site-cta"
                                          >
                                            Try full domain search
                                          </Button>
                                        </Tooltip>
                                      )}
                                  </Grid>
                                </Grid.Container>
                              )}
                          </Box>
                        )}
                    </Grid.Container>
                  </Card.Body>
                </Card>
              );
            })}
          {dataShown === "contacts" && (
            <Table
              aria-label="Example static collection table"
              css={{
                height: "auto",
                minWidth: "100%",
              }}
              selectionMode="multiple"
              defaultSelectedKeys={contacts
                .filter((contact) => contact.keep === true)
                .map((contact) => contact.id.toString())}
              bordered
              onSelectionChange={updateKeepStatus}
            >
              <Table.Header>
                <Table.Column>NAME</Table.Column>
                <Table.Column>POSITION</Table.Column>
                <Table.Column>EMAIL</Table.Column>
                <Table.Column>STATUS</Table.Column>

                <Table.Column>BACKLINK</Table.Column>
                <Table.Column>SOURCE</Table.Column>
              </Table.Header>
              <Table.Body>
                {contacts &&
                  contacts
                    .filter((contact) => {
                      if (viewParams.onlyValid && viewParams.onlySelected) {
                        return (
                          contact.verification === "valid" &&
                          contact.keep === true
                        );
                      } else if (viewParams.onlyValid) {
                        return contact.verification === "valid";
                      } else if (viewParams.onlySelected) {
                        return contact.keep === true;
                      } else {
                        return contact.email;
                      }
                    })
                    .sort((a, b) => a.backlink_id.id > b.backlink_id.id)
                    .map((contact) => {
                      return (
                        <Table.Row key={contact.id}>
                          <Table.Cell>
                            {contact.first_name} {"  "} {contact.last_name}
                          </Table.Cell>
                          <Table.Cell>{contact.position}</Table.Cell>
                          <Table.Cell>{contact.email}</Table.Cell>

                          <Table.Cell>
                            {contact.verification !== null && (
                              <Badge
                                color={
                                  contact.verification === "valid"
                                    ? "success"
                                    : "warning"
                                }
                                variant="flat"
                              >
                                {contact.verification}
                              </Badge>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <User
                              squared
                              src={
                                "https://alert-pink-iguana.faviconkit.com/" +
                                new URL(contact.backlink_id.url_from).hostname +
                                "/256"
                              }
                              name={
                                contact.backlink_id.title.length > 50
                                  ? contact.backlink_id.title.substring(0, 50) +
                                    "..."
                                  : contact.backlink_id.title
                              }
                              css={{ p: 0, maxW: 1100 }}
                              zoomed
                              pointer
                            >
                              <User.Link
                                href={contact.backlink_id.url_from}
                                pointer
                              >
                                {contact.backlink_id.url_from.length > 50
                                  ? contact.backlink_id.url_from.substring(
                                      0,
                                      50
                                    ) + "..."
                                  : contact.backlink_id.url_from}
                              </User.Link>
                            </User>
                          </Table.Cell>
                          <Table.Cell>
                            {contact.source === "clearout" && (
                              <Tooltip
                                content={
                                  "Contact found using our partner Clearout.io"
                                }
                                placement="bottom"
                              >
                                <Image
                                  src={clearoutLogo}
                                  width={20}
                                  showSkeleton
                                />
                              </Tooltip>
                            )}
                            {contact.source === "voila-norbert" && (
                              <Tooltip
                                content={
                                  "Contact found using our partner Voila Norbert"
                                }
                                placement="bottom"
                              >
                                <Image
                                  src={voilaNorbertLogo}
                                  width={20}
                                  showSkeleton
                                />
                              </Tooltip>
                            )}
                            {(contact.source === "snovio" ||
                              contact.source === "snovio-v2") && (
                              <Tooltip
                                content={
                                  "Contact found using our partner Snov.io"
                                }
                                placement="bottom"
                              >
                                <Image src={snovLogo} width={15} showSkeleton />
                              </Tooltip>
                            )}
                            {contact.source === "zeus-own" && (
                              <Tooltip
                                content={
                                  "Contact found using Zeus's own algorithm"
                                }
                                placement="bottom"
                              >
                                <Image src={zeusLogo} width={15} showSkeleton />
                              </Tooltip>
                            )}
                            {contact.source === "useArtemis" && (
                              <Tooltip
                                content={
                                  "Contact found using our partner useArtemis"
                                }
                                placement="bottom"
                              >
                                <Image
                                  src={useArtemisLogo}
                                  width={20}
                                  showSkeleton
                                />
                              </Tooltip>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
              </Table.Body>

              <Table.Pagination
                // total number of pages
                total={
                  contacts.filter((contact) => {
                    if (viewParams.onlyValid && viewParams.onlySelected) {
                      return (
                        contact.verification === "valid" &&
                        contact.keep === true
                      );
                    } else if (viewParams.onlyValid) {
                      return contact.verification === "valid";
                    } else if (viewParams.onlySelected) {
                      return contact.keep === true;
                    } else {
                      return contact.email;
                    }
                  }).length > 10
                    ? Math.ceil(
                        contacts.filter((contact) => {
                          if (viewParams.onlyValid && viewParams.onlySelected) {
                            return (
                              contact.verification === "valid" &&
                              contact.keep === true
                            );
                          } else if (viewParams.onlyValid) {
                            return contact.verification === "valid";
                          } else if (viewParams.onlySelected) {
                            return contact.keep === true;
                          } else {
                            return contact.email;
                          }
                        }).length / 10
                      )
                    : 1
                }
                initialPage={1}
                shadow
                noMargin
                align="center"
                rowsPerPage={10}
              />
            </Table>
          )}
          {dataShown === "backlinks" && (
            <Table
              aria-label="Example static collection table"
              css={{
                height: "auto",
                minWidth: "100%",
              }}
              selectionMode="multiple"
              defaultSelectedKeys={backlinks
                .filter((backlink) => backlink.keep === true)
                .map((backlink) => backlink.id.toString())}
              bordered
              onSelectionChange={updateKeepStatus}
            >
              <Table.Header>
                <Table.Column>BACKLINK</Table.Column>
                {/* <Table.Column>UR</Table.Column> */}
                <Table.Column>DR</Table.Column>
                {/* <Table.Column>ANCHOR</Table.Column> */}
              </Table.Header>
              <Table.Body>
                {backlinks &&
                  backlinks.length > 0 &&
                  backlinks
                    .filter((backlink) => backlink.keep === true)
                    .map((backlink) => {
                      return (
                        <Table.Row key={backlink.id}>
                          <Table.Cell>
                            <User
                              squared
                              src={
                                "https://alert-pink-iguana.faviconkit.com/" +
                                new URL(backlink.url_from).hostname +
                                "/256"
                              }
                              name={
                                backlink.title.length > 80
                                  ? backlink.title.substring(0, 80) + "..."
                                  : backlink.title
                              }
                              css={{ p: 0, maxW: 1100 }}
                              zoomed
                              pointer
                            >
                              <User.Link href={backlink.url_from} pointer>
                                {backlink.url_from.length > 80
                                  ? backlink.url_from.substring(0, 80) + "..."
                                  : backlink.url_from}
                              </User.Link>
                            </User>
                          </Table.Cell>
                          {/* <Table.Cell>
                            <Badge variant="flat">
                              {backlink["ahrefs_rank"]}
                            </Badge>
                          </Table.Cell> */}
                          <Table.Cell>
                            <Badge variant="flat">
                              {backlink["domain_rating"]}
                            </Badge>
                          </Table.Cell>
                          {/* <Table.Cell>
                            <Badge variant="flat">{backlink["anchor"]}</Badge>
                          </Table.Cell> */}
                        </Table.Row>
                      );
                    })}
              </Table.Body>

              <Table.Pagination
                // total number of pages
                total={
                  backlinks.filter((backlink) => backlink.keep).length > 10
                    ? Math.round(
                        backlinks.filter((backlink) => backlink.keep).length /
                          10
                      )
                    : 1
                }
                initialPage={1}
                shadow
                noMargin
                align="center"
                rowsPerPage={10}
              />
            </Table>
          )}
        </Box>
      ) : (
        <Grid justify="center" align="center">
          <Loading color="primary" size="md">
            Loading Emails...
          </Loading>
        </Grid>
      )}
    </Box>
  );
}

export default PickContacts;
