import {
  Loading,
  Pagination,
  Grid,
  Container,
  Card,
  Button,
  Text,
  Progress,
  Tooltip,
  Modal,
  Link,
  Avatar,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Box } from "../../design-structure/Box";
import { useParams } from "react-router-dom";
import LeftArrow from "../../icons/LeftArrow";
import HelpIcon from "../../icons/Help";
import { useNavigate } from "react-router-dom";

import supabase from "../../../supabase";
import SendIcon from "../../icons/Send";

import backend from "../../../backend";
import Send from "../../icons/Send";
import SearchIcon from "../../icons/Search";
import MaleAvatar from "../../../assets/male-avatar.png";
import DomainAvatar from "../../../assets/domain-avatar.png";

function ScreenLeads() {
  const [isLoading, setIsLoading] = useState(true);
  const [backlinks, setBacklinks] = useState([]);
  const [currentLead, setCurrentLead] = useState(1);
  const [contactSearchMode, setcontactSearchMode] = useState("author");
  const [modalIsVisible, setVisibleModal] = useState(false);
  const [domainSearchActive, setDomainSearchActive] = useState(true);
  const [backlinksCount, setBacklinkCount] = useState(0);
  const modalHandler = () => setVisibleModal(true);

  const closeModalHandler = () => {
    setVisibleModal(false);
    console.log("closed");
  };

  const setCheckedMode = (mode) => {
    setcontactSearchMode(mode);
  };

  const navigate = useNavigate();

  const campaignId = useParams();

  const handleChange = async (state, id) => {
    if (!state) {
      setBacklinks((prevBacklinks) => {
        return prevBacklinks.map((backlink) => {
          if (id === backlink.id) {
            const main = async () => {
              const { error } = await supabase
                .from("backlinks")
                .update({ keep: state })
                .eq("id", backlink.id);

              if (error) {
                console.log(error);
              }
            };

            main();

            backlink.keep = false;
          }

          return backlink;
        });
      });
      if (
        currentLead ===
        backlinks.filter((backlink) => backlink.keep === true).length + 1
      ) {
        switchLead(currentLead - 1);
      }
    } else {
      switchLead(
        currentLead >=
          backlinks.filter((backlink) => backlink.keep === true).length
          ? 1
          : currentLead + 1
      );
    }
  };

  const onSubmit = (mode = "author") => {
    backend
      .queryBackend("POST", "/campaign/get-emails", {
        campaignId: parseInt(campaignId.campaignId),
        mode,
      })
      .then((data) => data.json())
      .then((data) => {
        if (data.type === "no credit") {
          navigate("/?no_credit=true");
        } else {
          navigate("/");
        }
      });
  };

  const switchLead = (event) => {
    setCurrentLead(event);
  };

  const onClickPreviousPage = () => {
    navigate(`/campaign/backlinks/${campaignId.campaignId}`);
  };

  const generateThumURL = (url) => {
    return `https://image.thum.io/get/auth/65844-5aa0fbac46e373e6e7f8dafc146325ce/width/1300/allowJPG/noanimate/${url}`;
  };

  useEffect(() => {
    const fetchBacklinks = async () => {
      const data = await supabase
        .from("backlinks")
        .select(
          "id, url_from, ahrefs_rank, domain_rating, title, url_to, anchor, keep, screenshot_url, user_id, traffic_value"
        )
        .eq("requests_id", campaignId.campaignId)
        .eq("keep", true)
        .order("id", { ascending: false });
      if (data.error) {
        console.log(error);
      }
      setBacklinks(data.data);
      setBacklinkCount(data.data.filter((b) => b.keep === true).length);

      setIsLoading(false);
      if (window.beam) window.beam("/app/screen-leads");
    };

    fetchBacklinks();
  }, []);

  return (
    <Box>
      {!isLoading ? (
        <Grid alignItems="center" alignContent="center">
          <Grid.Container>
            <Grid xs={2} justify="left">
              <Button
                onPress={onClickPreviousPage}
                animated
                auto
                flat
                justify="right"
                icon={<LeftArrow fill="primary" />}
              >
                Back to backlinks list
              </Button>
            </Grid>
            <Grid xs={8} justify="center">
              <Pagination
                total={
                  backlinks.filter((backlink) => backlink.keep === true).length
                }
                initialPage={currentLead}
                page={
                  currentLead >= backlinks.length
                    ? backlinks.length
                    : currentLead
                }
                shadow
                size="lg"
                loop
                align="center"
                rowsPerPage={1}
                onChange={switchLead}
              />
            </Grid>
            <Grid xs={2} justify="right">
              <Button
                //onPress={() => onSubmit()}
                onPress={modalHandler}
                animated
                auto
                shadow
                justify="right"
                icon={<SendIcon fill="currentColor" />}
              >
                I'm done filtering. Now find contacts to email.
              </Button>
              <Modal
                closeButton
                aria-labelledby="modal-title"
                open={modalIsVisible}
                blur
                onClose={() => closeModalHandler()}
                width="50rem"
                autoMargin
              >
                <Modal.Header autoMargin>
                  <Text id="modal-title" size={18}>
                    How should Zeus find emails?
                  </Text>
                </Modal.Header>
                <Modal.Body autoMargin>
                  <Grid.Container gap={2}>
                    <Grid xs={6} alignContent="center" alignItems="center">
                      <Card
                        isPressable
                        isHoverable
                        variant={
                          contactSearchMode === "author" ? "shadow" : "flat"
                        }
                        onPress={({ target }) => setCheckedMode("author")}
                      >
                        <Card.Body>
                          <Grid.Container>
                            <Grid xs={4}>
                              <Avatar
                                css={{ ml: "$5" }}
                                size="xl"
                                src={MaleAvatar}
                                color="gradient"
                              />
                            </Grid>
                            <Grid
                              xs={8}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Text b>
                                Author Mode
                                <Text size="$xs">
                                  Best for personalized outreach
                                </Text>
                              </Text>
                            </Grid>
                          </Grid.Container>
                        </Card.Body>
                      </Card>
                    </Grid>
                    <Grid xs={6} alignContent="center" alignItems="center">
                      <Card
                        isPressable
                        isHoverable
                        variant={
                          contactSearchMode === "domain" ? "shadow" : "flat"
                        }
                        onPress={({ target }) => setCheckedMode("domain")}
                      >
                        <Card.Body>
                          <Grid.Container>
                            <Grid xs={4}>
                              <Avatar
                                css={{ ml: "$5" }}
                                size="xl"
                                src={DomainAvatar}
                                color="gradient"
                              />
                            </Grid>
                            <Grid
                              xs={8}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Text b>
                                Domain Mode{" "}
                                <Text size="$xs">
                                  Best for scaling outreach
                                </Text>
                              </Text>
                            </Grid>
                          </Grid.Container>
                        </Card.Body>
                      </Card>
                    </Grid>
                  </Grid.Container>
                  {contactSearchMode === "author" && (
                    <Box css={{ mt: "$5" }}>
                      <Card variant="bordered">
                        <Card.Body>
                          <Grid.Container
                            alignItems="center"
                            justify="left"
                            css={{ mb: "$5" }}
                          >
                            <Grid>
                              <Avatar
                                squared
                                icon={
                                  <HelpIcon size={20} fill="currentColor" />
                                }
                                css={{ mr: "$5" }}
                              />
                            </Grid>
                            <Grid>
                              <Text>
                                Zeus will find the page author name and return
                                his verified email address
                              </Text>
                            </Grid>
                          </Grid.Container>
                          <Grid.Container
                            alignItems="center"
                            justify="left"
                            css={{ mb: "$5" }}
                          >
                            <Grid>
                              <Avatar
                                squared
                                icon={<Send size={20} fill="currentColor" />}
                                css={{ mr: "$5" }}
                              />
                            </Grid>
                            <Grid>
                              <Text>
                                In lack of it, Zeus will scan the website for
                                the verified email of an editor or publisher
                              </Text>
                            </Grid>
                          </Grid.Container>
                          <Grid.Container alignItems="center" justify="left">
                            <Grid>
                              <Avatar
                                squared
                                icon={<SearchIcon size={20} fill="#000000" />}
                                css={{ mr: "$5" }}
                              />
                            </Grid>
                            <Grid>
                              <Text>
                                If still no email can be found, Zeus will
                                proceed with a full domain search
                              </Text>
                            </Grid>
                          </Grid.Container>
                        </Card.Body>
                      </Card>
                      <Text css={{ mt: "$5" }} color="warning">
                        If an email is found, this will cost 1 email credit.
                        Given that you've selected {backlinksCount} backlinks
                        for this campaign, the maximum amount of email credits
                        you will use is {backlinksCount}.
                      </Text>
                    </Box>
                  )}
                  {contactSearchMode === "domain" && (
                    <Box css={{ mt: "$5" }}>
                      <Card variant="bordered">
                        <Card.Body>
                          <Grid.Container
                            alignItems="center"
                            justify="left"
                            css={{ mb: "$5" }}
                          >
                            <Grid>
                              <Avatar
                                squared
                                icon={
                                  <HelpIcon size={20} fill="currentColor" />
                                }
                                css={{ mr: "$5" }}
                              />
                            </Grid>
                            <Grid>
                              <Text>
                                Zeus will complete a full domain search and find
                                up to 10 verified emails per backlink
                              </Text>
                            </Grid>
                          </Grid.Container>
                          <Grid.Container alignItems="center" justify="left">
                            <Grid>
                              <Avatar
                                squared
                                icon={<Send size={20} fill="currentColor" />}
                                css={{ mr: "$5" }}
                              />
                            </Grid>
                            <Grid>
                              <Text>
                                All 10 emails will be added to the export in CSV
                                right away
                              </Text>
                            </Grid>
                          </Grid.Container>
                        </Card.Body>
                      </Card>
                      <Text css={{ mt: "$5" }} color="warning">
                        If an email is found, this will cost 1 email credit.
                        <br />
                        Given that you've selected {backlinksCount} backlinks
                        for this campaign, the maximum amount of email credits
                        you will use is {backlinksCount}.
                      </Text>
                    </Box>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    auto
                    flat
                    color="error"
                    onPress={() => closeModalHandler()}
                  >
                    Close
                  </Button>
                  <Button
                    auto
                    onPress={() => {
                      onSubmit(contactSearchMode, domainSearchActive);
                    }}
                  >
                    Start search
                  </Button>
                </Modal.Footer>
              </Modal>
            </Grid>
          </Grid.Container>
          <Container css={{ mt: "$10", px: "0" }}>
            {backlinks &&
              [
                backlinks.filter((backlink) => backlink.keep === true)[
                  currentLead // we only the card that's being requested (to avoid too many simultaneous requests)
                ],
              ].map((backlink, index) => {
                if (backlink) {
                  const screenshotURLNext = generateThumURL(backlink.url_from);
                  return (
                    <Card.Image
                      css={{ d: "none" }}
                      src={screenshotURLNext}
                      alt="Loading screenshot..."
                      objectFit="fill"
                    />
                  );
                }
              })}
            {backlinks &&
              [
                backlinks.filter((backlink) => backlink.keep === true)[
                  currentLead - 1 // we only the card that's being requested (to avoid too many simultaneous requests)
                ],
              ].map((backlink, index) => {
                const screenshotURL = generateThumURL(backlink.url_from);
                return (
                  <Card css={{ display: "block" }} key={backlink.id}>
                    <Card.Header>
                      <Grid.Container gap={1}>
                        <Grid xs={1} direction="column">
                          <Text align="center">
                            {backlink["ahrefs_rank"] === -1
                              ? "Traffic Value"
                              : "URL Rating"}
                          </Text>
                          <Text h4 align="center" css={{ mt: 0, mb: 0 }}>
                            {backlink["ahrefs_rank"] === -1
                              ? backlink.traffic_value === -1
                                ? "N/A"
                                : backlink.traffic_value
                              : backlink.ahrefs_rank}
                          </Text>
                          <Progress
                            color="gradient"
                            value={
                              backlink["ahrefs_rank"] === -1
                                ? backlink.traffic_value
                                : backlink.ahrefs_rank
                            }
                          />
                        </Grid>
                        <Grid xs={1} direction="column">
                          <Text align="center">Domain</Text>
                          <Text h4 align="center" css={{ mt: 0, mb: 0 }}>
                            {backlink.domain_rating}
                          </Text>
                          <Progress
                            color="gradient"
                            value={backlink.domain_rating}
                          />
                        </Grid>
                        <Grid xs={7}>
                          <Grid.Container css={{ pl: "$6" }}>
                            <Grid xs={12}>
                              <Text b>{backlink.title}</Text>
                            </Grid>
                            <Grid xs={12} gap={0}>
                              <Text css={{ color: "$accents8" }}>
                                <Link
                                  css={{ color: "#687076" }} //underline={false}
                                  isExternal
                                  target="_blank"
                                  href={backlink.url_from}
                                >
                                  {backlink.url_from}
                                </Link>
                              </Text>
                            </Grid>
                          </Grid.Container>
                        </Grid>

                        <Grid xs={3}>
                          <Grid.Container alignItems="center">
                            <Grid
                              md={6}
                              xs={12}
                              justify="right"
                              css={{ px: "$5" }}
                            >
                              <Button
                                flat
                                auto
                                onPress={() => handleChange(true, backlink.id)}
                              >
                                Keep lead
                              </Button>
                            </Grid>
                            <Grid xs={12} md={6} justify="left">
                              <Tooltip
                                content={
                                  "Disregard this lead from your campaign (ie. spam, not qualified, not valid)."
                                }
                              >
                                <Button
                                  flat
                                  color="error"
                                  auto
                                  onPress={() =>
                                    handleChange(false, backlink.id)
                                  }
                                >
                                  Remove lead
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid.Container>
                        </Grid>
                      </Grid.Container>
                    </Card.Header>

                    <Card.Image
                      src={screenshotURL}
                      alt="Loading screenshot..."
                      objectFit="fill"
                      showSkeleton
                    />
                    <Card.Divider />
                  </Card>
                );
              })}
          </Container>
        </Grid>
      ) : (
        <Grid justify="center" align="center">
          <Loading color="primary" size="md">
            Loading Backlinks...
          </Loading>
        </Grid>
      )}
    </Box>
  );
}
export default ScreenLeads;
