import { Grid } from "@nextui-org/react";
import { Outlet } from "react-router-dom";

function IndexEmpty() {
  return (
    <>
      <Grid.Container
        gap={2}
        justify="center"
        alignContent="stretch"
        css={{ h: "100vh" }}
      >
        <Outlet />
      </Grid.Container>
    </>
  );
}

export default IndexEmpty;
