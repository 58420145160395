import { ZeusLogo } from "./ZeusLogo";
import { Navbar, Link, Badge, Tooltip } from "@nextui-org/react";
import { useAuth } from "../authProvider";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "./icons/Notification";
import { Layout } from "./design-structure/Layout";
import HelpIcon from "./icons/Help";

function TopNavBar() {
  const auth = useAuth();
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  const logOut = () => {
    auth.logout();
  };

  return (
    <Navbar variant="static">
      <Navbar.Brand>
        <ZeusLogo />
      </Navbar.Brand>
      <Navbar.Toggle showIn="xs" aria-label="toggle navigation" />
      {currentPath.indexOf("/campaign/") > -1 &&
        currentPath.indexOf("/campaign/search-results/") === -1 && (
          <Layout>
            <Navbar isCompact className="progress-navbar">
              {currentPath.indexOf("/campaign/backlinks") > -1 && (
                <Navbar.Content activeColor="primary" variant="default">
                  <Navbar.Link isActive isDisabled>
                    ✅ 1. Find backlinks
                  </Navbar.Link>
                  <Navbar.Link href="#" weight="thin">
                    2. Screen backlinks
                  </Navbar.Link>
                  <Navbar.Link href="#">3. Find contacts</Navbar.Link>
                </Navbar.Content>
              )}
              {currentPath.indexOf("/campaign/screen-leads") > -1 && (
                <Navbar.Content activeColor="primary" variant="default">
                  <Navbar.Link isDisabled>1. Find backlinks</Navbar.Link>
                  <Navbar.Link isActive href="#">
                    {" "}
                    ✅ 2. Screen backlinks
                  </Navbar.Link>
                  <Navbar.Link isDisabled href="#">
                    3. Find contacts
                  </Navbar.Link>
                </Navbar.Content>
              )}
              {currentPath.indexOf("/campaign/contacts") > -1 && (
                <Navbar.Content activeColor="primary" variant="default">
                  <Navbar.Link isDisabled>1. Find backlinks</Navbar.Link>
                  <Navbar.Link isDisabled href="#">
                    2. Screen backlinks
                  </Navbar.Link>
                  <Navbar.Link isActive href="#">
                    ✅ 3. Find contacts
                  </Navbar.Link>
                </Navbar.Content>
              )}
            </Navbar>
          </Layout>
        )}

      {auth.user && (
        <Navbar.Content hideIn="xs" enableCursorHighlight variant="underline">
          <Navbar.Link
            isActive={currentPath.indexOf("/latest") > -1}
            onPress={() => navigate("/latest")}
          >
            <Tooltip content="Latest updates" placement="bottom">
              <NotificationIcon />
            </Tooltip>
          </Navbar.Link>
          <Navbar.Link
            isActive={currentPath.indexOf("/help") > -1}
            onPress={() => navigate("/help")}
          >
            <Tooltip content="Help" placement="bottom">
              <HelpIcon />
            </Tooltip>
          </Navbar.Link>
          <Navbar.Link
            isActive={
              currentPath === "/" || currentPath.indexOf("/campaign/") > -1
            }
            onPress={() => navigate("/")}
          >
            Campaigns
          </Navbar.Link>
          <Navbar.Link
            isActive={currentPath === "/billing"}
            onPress={() => navigate("/billing")}
          >
            Billing
          </Navbar.Link>
          <Navbar.Link onClick={logOut}>Log out</Navbar.Link>
        </Navbar.Content>
      )}
      {!auth.user && (
        <Navbar.Content hideIn="xs" enableCursorHighlight variant="underline">
          <Navbar.Link
            isActive={currentPath === "/login"}
            onPress={() => navigate("/login")}
          >
            Log in
          </Navbar.Link>
          <Navbar.Link
            isActive={currentPath === "/signup"}
            onPress={() => navigate("/signup")}
          >
            Sign up
          </Navbar.Link>
        </Navbar.Content>
      )}

      {!auth.user && (
        <Navbar.Collapse>
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/login"
            >
              Log in
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/signup"
            >
              Sign Up
            </Link>
          </Navbar.CollapseItem>
        </Navbar.Collapse>
      )}
      {auth.user && (
        <Navbar.Collapse>
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/"
            >
              Campaigns
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/billing"
            >
              Billing
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              onClick={logOut}
            >
              Log Out
            </Link>
          </Navbar.CollapseItem>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
}

export default TopNavBar;
