import { Text, Card, Grid, Button, Loading, Avatar } from "@nextui-org/react";
import { Box } from "../design-structure/Box";

import supabase from "../../supabase";
import { useEffect, useState } from "react";
import TickIcon from "../icons/Tick";

import backend from "../../backend";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

function Billing() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState({
    credits: 0,
    email_credits: 0,
  });
  const navigate = useNavigate();

  const [planUpgrade, isplanUpgrade] = useState({
    status: null,
    message: null,
    color: null,
  });

  const [buttonLoadingLeft, isButtonLoadingLeft] = useState(false);
  const [buttonLoadingRight, isButtonLoadingRight] = useState(false);

  const onSubmit = (button, plan) => {
    if (button === "left") {
      isButtonLoadingLeft(true);
    } else if (button === "right") {
      isButtonLoadingRight(true);
    }
    backend
      .queryBackend("POST", `/user/upgrade/${plan}`)
      .then((data) => data.json())
      .then((data) => {
        async function main() {
          const stripe = await loadStripe(
            import.meta.env.VITE_STRIPE_PUBLIC_KEY
          );
          await stripe.redirectToCheckout({
            sessionId: data.id,
          });
        }

        main();
      });
  };

  useEffect(() => {
    async function main() {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const billing = await supabase
        .from("profiles")
        .select("credits, email_credits")
        .eq("id", user.id);

      setUser({
        credits: billing.data[0].credits,
        email_credits: billing.data[0].email_credits,
      });

      if (window.beam) window.beam("/app/billing");

      isplanUpgrade({
        status: searchParams.get("upgrade"),
        message:
          searchParams.get("upgrade") === "success"
            ? "Successfully Upgrade"
            : "Error Upgrading",
        color:
          searchParams.get("success") === "success" ? "primary" : "secondary",
      });
    }

    main();
  }, []);

  return (
    <Box>
      <Text h1>Billing</Text>

      <Grid.Container gap={2}>
        {planUpgrade.status === "success" && (
          <Card
            isHoverable
            isPressable
            variant="bordered"
            css={{ $$cardColor: "$colors$primary" }}
            onPress={() => {
              navigate("/");
            }}
          >
            <Card.Body>
              <Text color="white">
                Congrats! Your plan has been successfully updated. Click here to
                start using your new credits now.
              </Text>
            </Card.Body>
          </Card>
        )}
        {planUpgrade.status === "error" && (
          <Card
            isHoverable
            variant="bordered"
            css={{ $$cardColor: "$colors$error" }}
          >
            <Card.Body>
              <Text color="white">
                Oops! An error occured with your purchase. Please try again
                below or contact our support at david@zeusoutreach.com for more
                information.
              </Text>
            </Card.Body>
          </Card>
        )}
        <Grid xs={6}>
          <Card css={{ p: "$6" }}>
            <Card.Header>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Credit overview
              </Text>
            </Card.Header>
            <Card.Body css={{ py: "$2" }}>
              {user.credits !== null && (
                <>
                  <Text>
                    You have {user.credits} backlink credits and{" "}
                    {user.email_credits} email credits available. Click here to{" "}
                    <Link
                      target="_blank"
                      to="https://billing.stripe.com/p/login/cN229g7nR8661VK5kk"
                    >
                      here to manage your plan.
                    </Link>
                  </Text>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card css={{ p: "$6" }}>
            <Card.Header>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Need more credits?
              </Text>
            </Card.Header>
            <Card.Body css={{ py: "$2" }}>
              <Text>Pick any of the plans below to get more credits.</Text>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
      <Grid.Container gap={2}>
        <Grid xs={6}>
          <Card css={{ p: "$6" }}>
            <Card.Header>
              <Text h4>Starter Plan - $29/month</Text>
            </Card.Header>
            <Card.Body>
              <Text>The ideal plan to get serious about link building.</Text>
              <Card variant="flat" css={{ mt: "$6" }}>
                <Card.Body css={{ ml: "$6" }}>
                  <Text>
                    <TickIcon /> 500 backlinks credits.
                  </Text>
                  <Text>
                    <TickIcon /> 500 verified email credits.
                  </Text>
                  <Text>
                    <TickIcon /> Get leads in 3 minutes.
                  </Text>
                  <Text>
                    <TickIcon /> Only dofollow backlinks
                  </Text>
                  <Text>
                    <TickIcon /> Prospecting platform
                  </Text>
                </Card.Body>
              </Card>
            </Card.Body>
            <Grid.Container>
              <Grid
                xs={12}
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                {buttonLoadingLeft ? (
                  <Button
                    css={{ px: "$20" }}
                    disabled
                    auto
                    bordered
                    shadow
                    color="primary"
                  >
                    <Loading color="currentColor" size="sm" />
                  </Button>
                ) : (
                  <Button
                    shadow
                    color="primary"
                    auto
                    onPress={(event) => onSubmit("left", "starter")}
                  >
                    Get Immediate Access To Starter Plan
                  </Button>
                )}
              </Grid>
            </Grid.Container>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card css={{ p: "$6" }}>
            <Card.Header>
              <Text h4>Growth Plan - $79/month</Text>
            </Card.Header>
            <Card.Body>
              <Text>For those that want to start scaling and 10X leads.</Text>
              <Card variant="flat" css={{ mt: "$6" }}>
                <Card.Body css={{ ml: "$6" }}>
                  <Text>
                    <TickIcon /> 2,000 backlink credits.
                  </Text>
                  <Text>
                    <TickIcon /> 2,000 verified email credits.
                  </Text>
                  <Text>
                    <TickIcon /> Get leads in 3 minutes.
                  </Text>
                  <Text>
                    <TickIcon /> Only dofollow backlinks
                  </Text>
                  <Text>
                    <TickIcon /> Prospecting platform
                  </Text>
                </Card.Body>
              </Card>
            </Card.Body>
            <Grid.Container>
              <Grid
                xs={12}
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                {buttonLoadingRight ? (
                  <Button
                    css={{ px: "$20" }}
                    disabled
                    auto
                    bordered
                    shadow
                    color="primary"
                  >
                    <Loading color="currentColor" size="sm" />
                  </Button>
                ) : (
                  <Button
                    shadow
                    color="primary"
                    auto
                    onPress={() => onSubmit("right", "growth")}
                  >
                    Get Immediate Access To Growth Plan
                  </Button>
                )}
              </Grid>
            </Grid.Container>
          </Card>
        </Grid>
      </Grid.Container>
    </Box>
  );
}

export default Billing;
