import { Container, Text, Modal, Row, Button, Grid } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CardWithBackground } from "../design-structure/CardWithBackground";

import screenshotFirstCampaign from "../../assets/screenshots/1st-campaign.png";
import screenshotSecondCampaign from "../../assets/screenshots/2nd-campaign.png";
import screenshotFourthCampaign from "../../assets/screenshots/4th-campaign.png";
import liveDemoOne from "../../assets/screenshots/live-demos/thumb1.png";
import liveDemoTwo from "../../assets/screenshots/live-demos/thumb2.png";

function Help() {
  useEffect(() => {
    if (window.beam) window.beam("/app/help");
  });

  const [visible, setVisible] = useState(false);
  const handler = (id) => setVisible({ id });
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };

  const tellaVideos = [
    {
      title: "Create your first campaign",
      url: "https://www.tella.tv/video/cljvwv2jm018k0fmmfwns79qw/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Picking the best backlinks",
      url: "https://www.tella.tv/video/cljvxb6v6011w0fl971jj6yik/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Find emails & export data",
      url: "https://www.tella.tv/video/cljvxt17k00d20flibruz4goz/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Keyword: Best Golf Courses In America",
      url: "https://www.tella.tv/video/clj248j7200mf0fl38uxyelro/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
    {
      title: "Keyword: Managed WordPress Hosting",
      url: "https://www.tella.tv/video/clj6eyic600jj0fl54pbm4l7u/embed?b=0&title=1&a=1&loop=0&t=0&muted=0",
    },
  ];

  return (
    <Container lg css={{ px: "$12", mt: "$8", "@xsMax": { px: "$10" } }}>
      <Modal
        width={"70%"}
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {tellaVideos[visible.id] && tellaVideos[visible.id].title}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row justify="center">
            <iframe
              style={{ border: 0 }}
              width={800}
              height={450}
              src={tellaVideos[visible.id] && tellaVideos[visible.id].url}
              allowFullScreen=""
              allowTransparency=""
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onPress={closeHandler}>
            Close modal
          </Button>
        </Modal.Footer>
      </Modal>
      <Text h1>👋 Hey I'm David, Zeus' founder.</Text>
      <Text h4>
        Thanks for checking out Zeus! Below you'll find some key tutorials to
        maximize the value you get from Zeus.
      </Text>
      <Text h3>Getting Started</Text>
      <Grid.Container gap={2}>
        <Grid xs={6} sm={4}>
          <CardWithBackground
            h1="Create your 1st campaign"
            bgImage={screenshotFirstCampaign}
            onPress={() => handler(0)}
          />
        </Grid>
        <Grid xs={6} sm={4}>
          <CardWithBackground
            h1="Pick best backlinks"
            bgImage={screenshotSecondCampaign}
            onPress={() => handler(1)}
          />
        </Grid>

        <Grid xs={6} sm={4}>
          <CardWithBackground
            h1="Find emails & export data"
            bgImage={screenshotFourthCampaign}
            onPress={() => handler(2)}
          />
        </Grid>
      </Grid.Container>
      <Text h3>Live start-to-finish campaigns</Text>
      <Grid.Container gap={2}>
        <Grid xs={6} sm={4}>
          <CardWithBackground
            h1="Keyword: Best Golf Courses In America"
            bgImage={liveDemoOne}
            onPress={() => handler(3)}
          />
        </Grid>
        <Grid xs={6} sm={4}>
          <CardWithBackground
            h1="Keyword: Managed WordPress Hosting"
            bgImage={liveDemoTwo}
            onPress={() => handler(4)}
          />
        </Grid>
      </Grid.Container>
    </Container>
  );
}

export default Help;
