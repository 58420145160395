import supabase from "./supabase";
import { useState, useEffect, useContext, createContext } from "react";

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState();

  const signin = async (email, password, provider = false) => {
    const { data, error } =
      provider === "google"
        ? await supabase.auth.signInWithOAuth({
            provider,
            options: {
              redirectTo: `${import.meta.env.VITE_FRONTEND_URL}/confirm-email`,
            },
          })
        : await supabase.auth.signInWithPassword({
            email: email,
            password: password,
          });

    if (error) {
      console.log(error);
    }
    setUser(data.user);

    return { error, data };
  };

  const logout = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.log(error);
    }

    setUser(null);
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session !== null) {
        setUser(session.user);
      } else {
        setUser(false);
      }
    });

    return () => user;
  }, []);

  return {
    user,
    signin,
    logout,
  };
}
