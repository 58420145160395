import { useState, useCallback, useEffect } from "react";
import { useAuth } from "../../../authProvider";
import { useSearchParams } from "react-router-dom";
import {
  Text,
  Container,
  Input,
  styled,
  Button,
  Link,
  Card,
  Image,
} from "@nextui-org/react";

import googleSearchImage from "../../../assets/google-search-removebg.png";

const StyledForm = styled("form", {
  size: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  gap: "$md",
});

function Login() {
  const auth = useAuth();

  const [searchParams] = useSearchParams();
  debugger;
  if (searchParams.get("token")) {
    https: window.location.href = `https://mxgytwoqgdhiqhxahygc.supabase.co/auth/v1/verify?redirect_to=https://app.zeusoutreach.com/confirm-email&token=${searchParams.get(
      "token"
    )}&type=signup`;

    return;
  }

  useEffect(() => {
    if (window.beam) window.beam("/app/login");
  });

  const [isFormError, setIsFormError] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const updateFields = useCallback(
    (fields) => {
      setFormData((prev) => ({ ...prev, ...fields }));
    },
    [setFormData]
  );

  const onSubmit = async (provider = false) => {
    const signIn = await auth.signin(
      formData.email,
      formData.password,
      provider
    );
    if (signIn.error) {
      setIsFormError(true);
    } else {
      if (!provider) window.location.pathname = "/";
    }
  };
  return (
    <Container>
      <Container xs css={{ my: "$lg" }}>
        <StyledForm>
          <Text
            h1
            css={{
              ta: "center",
            }}
          >
            Login
          </Text>
          <Text
            h5
            css={{
              ta: "center",
            }}
          >
            Please login by entering your username and password below.
          </Text>
          {isFormError && (
            <Card
              isHoverable
              variant="bordered"
              css={{ $$cardColor: "$colors$error" }}
            >
              <Card.Body>
                <Text color="white">Incorrect email and/or password.</Text>
              </Card.Body>
            </Card>
          )}
          <Input
            name="email"
            label="Email"
            aria-label="email"
            placeholder="Enter your email"
            onChange={({ target }) => updateFields({ email: target.value })}
          />
          <Input.Password
            name="password"
            label="Password"
            aria-label="password"
            placeholder="Enter your password"
            onChange={({ target }) => updateFields({ password: target.value })}
          />
          <Button type="button" onClick={() => onSubmit("")}>
            Log in
          </Button>
          <Button
            type="button"
            color="white"
            onClick={() => onSubmit("google")}
          >
            <Image src={googleSearchImage} width="16px" height="16px" /> Sign in
            with Google
          </Button>
          <Text span blockquote css={{ m: 0 }}>
            Note: Don't have an account? Click here to{" "}
            <Link href="/signup">sign up</Link>.
          </Text>
        </StyledForm>
      </Container>
    </Container>
  );
}

export default Login;
